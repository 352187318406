import { Button, Statistic, Table, DatePicker } from 'antd';
import moment from 'moment';
import React, {  useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';

import DefaultLayout from '../components/DefualtLayout';
import {
   approveBonus,

   updateStatusToIsPaid,
} from '../features/adminFeatures/commission/commissionAdminSlice';

import { getBonusByDates } from '../features/commission/commissionSlice';
import commaNumber from 'comma-number';

const { RangePicker } = DatePicker;

const ExportBonusSelectDayKhmer = () => {


   const dispatch = useDispatch();
  
   const { bonus, commissions: commissions2 } = useSelector(
      (state) => state.commissionAdmin
   );
   const { commissions, isLoading } = useSelector((state) => state.commission);
   const { settings } = useSelector((state) => state.setting);
   const { userInfo } = useSelector((state) => state.auth);

   const [isPV, setIsPV] = useState(false);



   let bonusDesc = null;
   if (bonus.length >= 1) {
      bonusDesc = [...bonus]; // Create a copy of the original array

      bonusDesc.sort((a, b) => new Date(b.date) - new Date(a.date));
   }

   let keyPrice = null;
   let transferFee = null;
   let tax = null;
   if (settings) {
      keyPrice = settings
         .filter((obj) => obj.name.includes('keyPrice'))
         .map((obj) => obj.number);

      transferFee = settings
         .filter((obj) => obj.name.includes('transferFee'))
         .map((obj) => obj.number);

      tax = settings
         .filter((obj) => obj.name.includes('tax'))
         .map((obj) => obj.number);

      transferFee.toString();
      tax.toString();
   }

   // approve
   const { BonusData, BonusMobile } = commissions2;

   const sumByCardId = BonusData?.reduce((acc, curr) => {
      let identification;
      if (curr.cardId) {
         identification = curr.cardId;
      } else if (curr.passport) {
         identification = curr.passport;
         // identification = curr.user.passport || curr.user.passsport;
      } else if (curr.passsport) {
         identification = curr.passsport;
      }

      let isMobileGetPaid;
      if (curr.isMobileGetPaid) {
         isMobileGetPaid = true;
      } else {
         isMobileGetPaid = false;
         // identification = curr.user.passport || curr.user.passsport;
      }

      const { bankData, userId, _id: user_id, firstName, lastName } = curr;
      const name = `${firstName} ${lastName}`;

      if (!acc[identification]) {
         acc[identification] = {
            bankData: bankData,
            userId: userId,
            isMobileGetPaid: isMobileGetPaid,
            identification: identification,
            name: name,
            user_id: user_id,
            sum: 0,
            netSum: 0,
            tax: 0,
            fee: 30,
            status: curr.commission.status,
         };
      }
      acc[identification].sum += curr.commission.netSum;
      acc[identification].netSum += curr.commission.netSum;
      acc[identification].tax += curr.commission.tax;

      return acc;
   }, {});
   const sumByCardId2 = BonusMobile?.reduce((acc, curr) => {
      let identification;
      if (curr.cardId) {
         identification = curr.cardId;
      } else if (curr.passport) {
         identification = curr.passport;
         // identification = curr.user.passport || curr.user.passsport;
      } else if (curr.passsport) {
         identification = curr.passsport;
      }

      const { userId } = curr;

      if (!acc[identification]) {
         acc[identification] = {
            userId: userId,
            identification: identification,
            sum: 0,
            netSum: 0,
            tax: 0,
            fee: 30,
         };
      }
      acc[identification].sum += curr.bonusMobile.netSum;
      acc[identification].netSum += curr.bonusMobile.netSum;
      acc[identification].tax += curr.bonusMobile.tax;

      return acc;
   }, {});

   const combinedSumByCardId = { ...sumByCardId };
   for (const identification in sumByCardId2) {
      if (!combinedSumByCardId[identification]) {
         combinedSumByCardId[identification] = {
            sum: 0,
            netSum: 0,
            tax: 0,
            fee: 30,
            userId: sumByCardId2[identification].userId,
            identification: identification,
         };
      }
      combinedSumByCardId[identification].sum +=
         sumByCardId2[identification].sum;
      combinedSumByCardId[identification].netSum +=
         sumByCardId2[identification].netSum;
      combinedSumByCardId[identification].tax +=
         sumByCardId2[identification].tax;
   }

   // Convert combinedSumByCardId to array and filter based on netSum
   const combinedBonusToArray = Object.values(combinedSumByCardId).filter(
      (e) => e.netSum >= 50
   );

   // approve end
   

   let data = [];

   if (bonus.length >= 1) {
      data = bonus.map((e) => {
         return {
            ...e,
         };
      });
   }

   let filteredDataByDate = null;
   const onChange = (value, dateString) => {
      const sDate = dateString[0];
      const eDate = dateString[1];
      let dates = {};
      dates.eDate = eDate;
      dates.sDate = sDate;

      if (eDate || sDate) {
         dispatch(getBonusByDates(dates));
      }
   };

   const { totalOrdersArray, totalAmountArray, totalDepositsArray } =
      commissions;

   const mergedArray = [];

   if (
      totalAmountArray?.length >= 1 ||
      totalOrdersArray?.length >= 1 ||
      totalDepositsArray?.length >= 1
   ) {
      // Iterate over totalOrdersArray
      totalOrdersArray.forEach((orderItem) => {
         // Find corresponding entries in totalAmountArray and totalDepositsArray based on date
         const correspondingAmount = totalAmountArray.find(
            (amountItem) => amountItem.date === orderItem.date
         );
         const correspondingDeposit = totalDepositsArray.find(
            (depositItem) => depositItem.date === orderItem.date
         );

         // Merge data into a single object
         const mergedItem = {
            date: orderItem.date,
            totalPrice: orderItem.totalPrice,
            totalPv: orderItem.totalPv,
            amount: correspondingAmount ? correspondingAmount.amountBonus : 0, // If correspondingAmount exists, use its amount, otherwise default to 0
            depositAmount: correspondingDeposit
               ? correspondingDeposit.amount
               : 0, // If correspondingDeposit exists, use its amount, otherwise default to 0
            depositTotalPv: correspondingDeposit
               ? correspondingDeposit.totalPv
               : 0, // If correspondingDeposit exists, use its totalPv, otherwise default to 0
         };

         // Add mergedItem to mergedArray
         mergedArray.push(mergedItem);
      });

      // Iterate over totalAmountArray to find missing dates in totalOrdersArray
      totalAmountArray.forEach((amountItem) => {
         // Check if date from totalAmountArray does not exist in mergedArray
         if (!mergedArray.some((item) => item.date === amountItem.date)) {
            // Add a new object to mergedArray with the missing date and corresponding amount
            mergedArray.push({
               date: amountItem.date,
               totalPrice: 0,
               totalPv: 0,
               amount: amountItem.amount,
               depositAmount: 0,
               depositTotalPv: 0,
            });
         }
      });

      // Iterate over totalDepositsArray to find missing dates in totalOrdersArray
      totalDepositsArray.forEach((depositItem) => {
         // Check if date from totalDepositsArray does not exist in mergedArray
         if (!mergedArray.some((item) => item.date === depositItem.date)) {
            // Add a new object to mergedArray with the missing date and corresponding deposit amount and totalPv
            mergedArray.push({
               date: depositItem.date,
               totalPrice: 0,
               totalPv: 0,
               amount: 0,
               depositAmount: depositItem.amount,
               depositTotalPv: depositItem.totalPv,
            });
         }
      });

      // Sort mergedArray by date
      mergedArray.sort((a, b) => new Date(a.date) - new Date(b.date));
      mergedArray.forEach((item, index) => {
         item.index = index;
      });
   }

   const headers2 = [
      { label: 'ชื่อ-สกุล', key: 'name', width: 30 },
      { label: 'รหัสสมาชิก', key: 'userId' },
      { label: 'เลขบัตรประชาชนหรือพาสปอร์ต', key: 'identification' },
      { label: 'ธนาคาร', key: 'bankData.bank' },
      { label: 'เลขบัญชี', key: 'bankData.bankAccount' },
      { label: 'รายได้สุทธิ', key: 'netSum', width: 40 },
   ];

   const [selectedRows, setSelectedRows] = useState([]);

   const handleRowSelection = (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
   };

   const rowSelection = {
      selectedRowKeys: selectedRows.map((row) => row.index),
      onChange: handleRowSelection,
   };

   const handlerApproveBonus = () => {
      dispatch(approveBonus(selectedRows));
   };

   const handlerUpdateStatusToIsPaid = () => {
      dispatch(updateStatusToIsPaid(selectedRows));
   };

   const columns = [
      {
         title: 'ลำดับ',
         dataIndex: 'depth',
         key: 'depth',
         render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
         title: 'วันที่',
         dataIndex: 'date',
         key: 'date',
         sorter: (a, b) => new Date(a.date) - new Date(b.date),
      },
      {
         title: 'เข้า eWallet',
         dataIndex: 'depositAmount',
         key: 'depositAmount',
         render: (record) => (
            <span>
               {' '}
               <Statistic suffix='฿' value={record} />{' '}
            </span>
         ),
      },
      {
         title: 'ขาย eWallet',
         dataIndex: 'totalPv',
         key: 'totalPv',
         render: (record) => (
            <>
               {isPV ? (
                  <>
                     <span>
                        {' '}
                        <Statistic suffix='฿' value={record * 12} />{' '}
                     </span>
                  </>
               ) : (
                  <>
                     <span>
                        {' '}
                        <Statistic suffix='PV' value={record} />{' '}
                     </span>
                  </>
               )}
            </>
         ),
      },
      {
         title: 'ยอดโบนัส',
         dataIndex: 'amount',
         key: 'amount',
         render: (value, record) => {
            const formattedDate = moment(record.date, 'DD/MM/YYYY').format(
               'YYYY-MM-DD'
            );
            return (
               <>
                  <Link to={`/admin/exportBonusPerDayDetail/${formattedDate}`}>
                     <span className='text-underline'>
                        {commaNumber(value)}
                     </span>
                  </Link>
               </>
            );
         },
      },
      {
         title: '%',
         render: (record) => (
            <>
               <span>
                  {((record.amount / (record.totalPv * 12)) * 100).toFixed(2)}%
               </span>
            </>
         ),
      },
   ];

   const disabledDate = (current) => {
      return current && current > moment().endOf('day');
   };

 
   

   return (
      <DefaultLayout>
         {isLoading ? (
            <>
               <Loading />
            </>
         ) : (
            <>
               <div className='d-flex justify-content-between'>
                  <RangePicker
                     onChange={onChange}
                     allowClear
                     disabledDate={disabledDate}
                  />{' '}
                  <Button
                   style={{background: '#222831', color: 'white'}}
                     onClick={() => {
                        setIsPV((prevState) => !prevState);
                     }}
                  >
                     {' '}
                     ดูเป็นบาท{' '}
                  </Button>
                  {userInfo.isSuperAdmin ? null : (
                     <>
                        <Button
                        style={{color: 'black'}}
                           disabled={combinedBonusToArray.length < 1}
                          // onClick={() => handlerUpdateStatusToIsPaid()}
                        >
                           <CSVLink
                              filename={
                                 'สรุปยอดรายงารสรุปการจ่ายของโบนัสรายวัน.csv'
                              }
                              data={combinedBonusToArray}
                              headers={headers2}
                           >
                              export โบนัสจ่ายรอบอาทิตย์
                           </CSVLink>
                        </Button>
                        <Button
                         style={{background: '#5585b5', color: 'white'}}
                           onClick={() => handlerUpdateStatusToIsPaid()}
                        >
                        อนุมัติโบนัส 
                        </Button>
                        <Button
                           className='btn btn-success'
                           onClick={() => handlerApproveBonus()}
                        >
                           คำนวณโบนัส
                        </Button>
                     </>
                  )}
               </div>

               <div>
                  <Table
                  summary={(mergedArray) => {
                     let totalBonus = 0;
                     let totalDeposit = 0;
                     let totalPv = 0;

                     mergedArray.forEach(({amount, totalPv: pv,depositAmount}) => {
                        totalBonus += amount
                        totalPv += pv
                        totalDeposit += depositAmount
                     })

                     const formattedPercentage = commaNumber(((totalBonus / (totalPv * 12)) * 100).toFixed(2));

                     return(
                        <>
                        <Table.Summary.Row>
                           <Table.Summary.Cell>
                              <b>รวม</b>
                           </Table.Summary.Cell>
                           <Table.Summary.Cell></Table.Summary.Cell>
                           <Table.Summary.Cell></Table.Summary.Cell>
                           <Table.Summary.Cell>
                              <b>{commaNumber(totalDeposit)} ฿</b>
                           </Table.Summary.Cell>
                           <Table.Summary.Cell>
                              <b>{commaNumber(totalPv * 12 )} ฿</b>
                           </Table.Summary.Cell>
                           <Table.Summary.Cell>
                              <b>{commaNumber(totalBonus)} ฿</b>
                           </Table.Summary.Cell>
                           <Table.Summary.Cell>
                           <b>{formattedPercentage} % </b>

                           </Table.Summary.Cell>
                        </Table.Summary.Row>
                        </>
                     )
                  }}

                     columns={columns}
                     dataSource={mergedArray}
                     rowSelection={rowSelection}
                     rowKey={(record) => record.index}
                  />
               </div>
            </>
         )}
      </DefaultLayout>
   );
};


export default ExportBonusSelectDayKhmer
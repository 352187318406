import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getDataRegisterPerWeek } from '../features/order/orderSlice';
import { FaUser } from 'react-icons/fa';
import commaNumber from 'comma-number';
import { PiNumberCircleSevenFill } from "react-icons/pi";
import { IoMdAdd, IoMdStar } from "react-icons/io";

const TodaySale = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { userInfo } = useSelector((state) => state.auth);

   const { dataRegisterPerWeek } = useSelector((state) => state.order);

   const sumData = (acc, curr) => {
      const { registerMember, registerHolder, registerTester, topUp, renew } =
         curr;

      acc.registerMember.count += registerMember.count;
      acc.registerMember.totalPv += registerMember.totalPv;

      acc.registerHolder.count += registerHolder.count;
      acc.registerHolder.totalPv += registerHolder.totalPv;

      acc.registerTester.count += registerTester.count;
      acc.registerTester.totalPv += registerTester.totalPv;

      acc.topUp.count += topUp.count;
      acc.topUp.totalPv += topUp.totalPv;

      acc.renew.count += renew.count;
      acc.renew.totalPv += renew.totalPv;

      return acc;
   };

   const initialValue = {
      registerMember: { count: 0, totalPv: 0 },
      registerHolder: { count: 0, totalPv: 0 },
      registerTester: { count: 0, totalPv: 0 },
      topUp: { count: 0, totalPv: 0 },
      renew: { count: 0, totalPv: 0 },
   };

   let totalData = null;

   if (dataRegisterPerWeek.length >= 1) {
      totalData = dataRegisterPerWeek?.reduce(sumData, initialValue);
   }


   useEffect(() => {
      dispatch(getDataRegisterPerWeek());
   }, []);



   return (
      <div className=''>
     <div>
          <Row>
            <Col sm={24} xs={24} md={24} lg={24} xl={24}>
               {' '}
               <div
               className='main-shadow text-center'
               style={{backgroundColor: 'white', padding: '14px',borderRadius: '20px'}}
               >
                  <h5 className='mb-5 mt-2'>
                     <b>
                        ยอดขายรายสัปดาห์
                     </b>
                  </h5>
                  <Row gutter={[10, 10]} justify='center' >
                     <Col sm={12} xs={12} md={12} lg={8} xl={4}>
                        <Card backgroundColor='#fcff828f'    className='ml-auto mr-auto'>
                           <div
                              style={{ textAlign: 'center' }}
                              className='ml-auto mr-auto'
                           >
                              <div
                                 style={{
                                    backgroundColor: 'white',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '50%',
                                    margin: '0 auto',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                 }}
                              >
                                 <PiNumberCircleSevenFill  size={20} color='gray' />
                              </div>
                              <p
                                 style={{
                                    margin: '8px 0 4px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                 }}
                              >
                                 {totalData?.registerHolder?.count}{' '}
                                 <span>รหัส</span>
                              </p>
                              <p
                                 style={{
                                    margin: '4px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                 }}
                              >
                                 {commaNumber(
                                    totalData?.registerHolder?.totalPv
                                 )}{' '}
                                 <span>PV</span>
                              </p>
                              <p
                                 style={{
                                    margin: '4px 0',
                                    fontSize: '12px',
                                    color: '#666',
                                 }}
                              >
                                 ยอดสมัคร โฮลเดอร์
                              </p>
                           </div>
                        </Card>
                     </Col>
                     <Col sm={12} xs={12} md={12} lg={8} xl={4}>
                        <Card backgroundColor='#00bbf05c'>
                        <div
                              style={{ textAlign: 'center' }}
                           >
                              <div
                                 style={{
                                    backgroundColor: 'white',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '50%',
                                    margin: '0 auto',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                 }}
                              >
                                 <FaUser size={20} color='gray' />
                              </div>
                              <p
                                 style={{
                                    margin: '8px 0 4px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                 }}
                              >
                                 {totalData?.registerMember?.count}{' '}
                                 <span>รหัส</span>
                              </p>
                              <p
                                 style={{
                                    margin: '4px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                 }}
                              >
                                 {commaNumber(
                                    totalData?.registerMember?.totalPv
                                 )}{' '}
                                 <span>PV</span>
                              </p>
                              <p
                                 style={{
                                    margin: '4px 0',
                                    fontSize: '12px',
                                    color: '#666',
                                 }}
                              >
                                 ยอดสมัคร เมมเบอร์
                              </p>
                           </div>
                        </Card>
                     </Col>
                     <Col sm={12} xs={12} md={12} lg={8} xl={4}>
                        <Card backgroundColor='#d9dad7'>
                        <div
                              style={{ textAlign: 'center' }}
                           >
                              <div
                                 style={{
                                    backgroundColor: 'white',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '50%',
                                    margin: '0 auto',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                 }}
                              >
                                 <FaUser size={20} color='gray' />
                              </div>
                              <p
                                 style={{
                                    margin: '8px 0 4px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                 }}
                              >
                                 {totalData?.registerTester?.count}{' '}
                                 <span>รหัส</span>
                              </p>
                              <p
                                 style={{
                                    margin: '4px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                 }}
                              >
                                 {commaNumber(
                                    totalData?.registerTester?.totalPv
                                 )}{' '}
                                 <span>PV</span>
                              </p>
                              <p
                                 style={{
                                    margin: '4px 0',
                                    fontSize: '12px',
                                    color: '#666',
                                 }}
                              >
                                 ยอดสมัคร เทสเตอร์
                              </p>
                           </div>
                        </Card>
                     </Col>
                     <Col sm={12} xs={12} md={12} lg={8} xl={4}>
                        <Card backgroundColor='#e0ffcd'>
                        <div
                              style={{ textAlign: 'center' }}
                           >
                              <div
                                 style={{
                                    backgroundColor: 'white',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '50%',
                                    margin: '0 auto',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                 }}
                              >
                                 <IoMdStar  size={20} color='gray' />
                              </div>
                              <p
                                 style={{
                                    margin: '8px 0 4px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                 }}
                              >
                                 {totalData?.topUp?.count}{' '}
                                 <span>รหัส</span>
                              </p>
                              <p
                                 style={{
                                    margin: '4px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                 }}
                              >
                                 {commaNumber(
                                    totalData?.topUp?.totalPv
                                 )}{' '}
                                 <span>PV</span>
                              </p>
                              <p
                                 style={{
                                    margin: '4px 0',
                                    fontSize: '12px',
                                    color: '#666',
                                 }}
                              >
                                 ยอดท็อปอัพ
                              </p>
                           </div>
                        </Card>
                     </Col>
                     <Col sm={12} xs={12} md={12} lg={8} xl={4}>
                        <Card backgroundColor='#fdc57b'>
                        <div
                              style={{ textAlign: 'center' }}
                           >
                              <div
                                 style={{
                                    backgroundColor: 'white',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '50%',
                                    margin: '0 auto',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                 }}
                              >
                                 <IoMdAdd  size={20} color='gray' />
                              </div>
                              <p
                                 style={{
                                    margin: '8px 0 4px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                 }}
                              >
                                 {totalData?.renew?.count}{' '}
                                 <span>รหัส</span>
                              </p>
                              <p
                                 style={{
                                    margin: '4px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                 }}
                              >
                                 {commaNumber(
                                    totalData?.renew?.totalPv
                                 )}{' '}
                                 <span>PV</span>
                              </p>
                              <p
                                 style={{
                                    margin: '4px 0',
                                    fontSize: '12px',
                                    color: '#666',
                                 }}
                              >
                                 ยอดต่ออายุรหัส
                              </p>
                           </div>
                        </Card>
                     </Col>
                  </Row>
               </div>
            </Col>

        
         </Row>
     </div>
      </div>
   );
};

export default TodaySale;

const Card = styled.div`
   background-color: ${(props) => props.backgroundColor || '#fff'};
   border-radius: 8px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   padding: 16px;
   width: 150px;
   height: 150px;
`;

import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefualtLayout';
import { Button, Form, Input, List, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
   BanUser,
   UnBanUser,
   deleteUser,
   findUserByUserData,
   findUserByUserId,
   reset,
} from '../features/adminFeatures/users/userAdminSlice';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { updateUserToCenter } from '../features/users/usersSlice';
import {
   adminUpdateUser,
   adminUpdateUserPassword,
} from '../features/admin/userAdminSlice';

const { Search } = Input;

const MemberRegistration = () => {
   const navigate = useNavigate();
   const [userId, setUserId] = useState('');
   const [data, setData] = useState('');
   const dispatch = useDispatch();

   const [userData, setUserData] = useState({});
   const [isUpdate, setIsUpdate] = useState(false);
   const [isUpdateAllUserByCardId, setIsUpdateAllUserByCardId] =
      useState(false);

   const [isUpdatePassword, setIsUpdatePassword] = useState(false);

   const [password, setPassword] = useState('');

   const [form] = Form.useForm();

   const { userInfo } = useSelector((state) => state.auth);

   const {
      user,
      users,
      isLoading,
      isUpdateSuccess,
      deleteIsSuccess,
      isUpdateCenterSuccess,
      isUpdateCenterError,
      isError,
      message,
   } = useSelector((state) => state.userAdmin);

   const {isAdminUpdatesuccess, user: userFromAdmin}  = useSelector((state) => state.admin)

   const [isModalOpen, setIsModalOpen] = useState(false);
   const showModal = () => {
      setIsModalOpen(true);
   };
   const handleOk = () => {
      setIsModalOpen(false);
   };
   const handleCancel = () => {
      setIsModalOpen(false);
   };

   const handlerSearchByUserId = () => {
      dispatch(findUserByUserId(userId));

      setUserId('');
   };
   const handlerSearchByName = () => {
      dispatch(findUserByUserData(data));
      setIsModalOpen(true);
      setData('');
   };

   const handleDeleteUser = (id) => {
      const confirm = window.confirm('ยืนยัน');
      if (confirm === true) {
         dispatch(deleteUser(id));
      } else {
         window.location.reload();
      }
   };

   const handleBanUser = (id) => {
      const confirm = window.confirm('ยืนยัน');
      if (confirm === true) {
         dispatch(BanUser(id));
    //     window.location.reload();
      } else {
         window.location.reload();
      }
   };
   const handleUnBanUser = (id) => {
      const confirm = window.confirm('ยืนยัน');
      if (confirm === true) {
         dispatch(UnBanUser(id));
       //  window.location.reload();
      } else {
         window.location.reload();
      }
   };

   useEffect(() => {
      if (!userInfo.isAdmin) {
         navigate('/login');
      }
     

      if (user.cardId) {
         setUserData(user);
      }
      if (user.passsport) {
         setUserData(user);
      }
      if (user.passport) {
         setUserData(user);
      }

      if (isError) {
         toast.error(message);
      }
      if(isUpdateSuccess){
         toast.success('ทำรายการสำเร็จ');
         setUserData(user)
      }
      if(isAdminUpdatesuccess){
         toast.success('ทำรายการสำเร็จ');
         setUserData(userFromAdmin)
      }

      if (deleteIsSuccess) {
         toast.success('ทำรายการสำเร็จ');
      }
      if (isUpdateCenterSuccess) {
         toast.success('ทำรายการสำเร็จ');
      }
      if (isUpdateCenterError) {
         toast.error(message);
      }

      if (userData) {
         form.setFieldsValue({
            name: userData.name,
            firstName: userData.firstName,
            lastName: userData.lastName,
            beneficiary: userData.beneficiary,
            cardId: userData.cardId,
            passport: userData.passport,
            passsport: userData.passsport,
            pass: userData.pass,
            createdAt: userData.createdAt,
            address: userData.address?.address,
            subdistrict: userData.address?.subdistrict,
            district: userData.address?.district,
            province: userData.address?.province,
            postalCode: userData.address?.postalCode,
            bank: userData.bankData?.bank ? userData.bankData.bank : null,
            tel: userData?.contact?.tel,
            bankAccount: userData?.bankData?.bankAccount
               ? userData.bankData.bankAccount
               : null,
         });
      }

      return () => {
         dispatch(reset());
      };
   }, [
      form,
      userData,
      userData.userId,
      user,
      user.name,
      user.firstName,
      isError,
      deleteIsSuccess,
      isUpdateCenterSuccess,
      isUpdateCenterError,
      isUpdateSuccess,
      isAdminUpdatesuccess
   ]);

   console.log('userData:' , userData);

   const onFinish = (values) => {
      const userId = userData.userId;

      values.userId = userId;

      const address = {
         address: values.address,
         province: values.province,
         district: values.district,
         subdistrict: values.subdistrict,
         postalCode: values.postalCode,
      };
      const bankData = {
         bank: values.bank,
         bankAccount: values.bankAccount,
      };

      const contact = {
         tel: values.tel,
      };
      values.address = address;
      values.contact = contact;
      values.bankData = bankData;
      values.isUpdateAllUserByCardId = isUpdateAllUserByCardId;

      dispatch(adminUpdateUser(values));
      toast.success('ทำรายการสำเร็จ');

   
   };
   const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   const handlerSelectUser = (user) => {
      console.log('user select: ', user);
      setUserData(user);
      //  setIsModalOpen(false)
   };

   const handleIsCenter = () => {
      const confirm = window.confirm(
         `ยืนยัน ต้องการอัพเดทรหัส ${userData.userId} เป็นเซนเตอร์`
      );
      if (confirm === true) {
         dispatch(updateUserToCenter(userData._id));
      }
   };

   const handleUpdatePassword = () => {
      const confirm = window.confirm(
         `ยืนยัน ต้องการอัพเดทรหัสผ่าน ${userData.userId} `
      );

    //  console.log('password: ', password);
      const data = {};
      data.password = password;
      data.userId = userData.userId;

      console.log('data: ', data);

     if (confirm === true) {
        dispatch(adminUpdateUserPassword(data));
     }
   };

   const columns = [
      {
         title: 'ลำดับ',
         dataIndex: 'depth',
         key: 'depth',
         render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
         title: 'รหัสสมาชิก',
         dataIndex: 'userId',
         key: 'userId',
         render: (value, row) => (
            <>
               {' '}
               <span
                  className='cursor-pointer'
                  onClick={() => {
                     handlerSelectUser(row);
                     setIsModalOpen(false);
                  }}
               >
                  {value}
               </span>{' '}
            </>
         ),
      },
      {
         title: 'ชื่อ-สกุล',
         dataIndex: ['firstName, lastName'],
         render: (_, row) => (
            <>
               <span
                  className='cursor-pointer'
                  onClick={() => {
                     handlerSelectUser(row);
                     setIsModalOpen(false);
                  }}
               >
                  {row['firstName']} <span>{row['lastName']}</span>
               </span>
            </>
         ),
      },
      {
         title: 'วันที่สมัคร',
         dataIndex: 'createdAt',
         key: 'createdAt',
         render: (value, row) => <p> {moment(value).format('DD/MM/YYYY')} </p>,
      },
      {
         title: 'บัตร/พาสปอร์ต',
         render: ( row) => (
            <>
            {row.cardId ? (
              <p className='cursor-pointer' onClick={() => handlerSelectUser(row)}>
                {row.cardId}
              </p>
            ) : row.passport ? (
              <p className='cursor-pointer' onClick={() => handlerSelectUser(row)}>
                {row.passport}
              </p>
            ) : row.passsport ? (
              <p className='cursor-pointer' onClick={() => handlerSelectUser(row)}>
                {row.passsport }
              </p>
            ) : (
              <p className='cursor-pointer'>
                No valid ID
              </p>
            )}
          </>
         ),
      },
      {
         title: 'เลขที่บัญชี',
         dataIndex: 'bankData',
         key: 'bankData',
         render: (value, row) => (
            <>
               <p
                  className='cursor-pointer'
                  onClick={() => handlerSelectUser(row)}
               >
                  {value.bankAccount}
               </p>
            </>
         ),
      },
   ];

   return (
      <DefaultLayout>
         <>
            <div className='d-flex gap-2 '>
               <Search
                  placeholder='ค้นหาโดยรหัสสมาชิก'
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  allowClear
                  onSearch={handlerSearchByUserId}
               />
               <Search
                  placeholder='ค้นหาโดยเลขบัตรประชาชนหรือชื่อ'
                  value={data}
                  onChange={(e) => setData(e.target.value)}
                  allowClear
                  onSearch={handlerSearchByName}
               />
            </div>
            <div className='m-3 '>
               {userData.name && (
                  <>
                     <div className='d-flex flex-row justify-content-between'>
                        <div>
                           <h5>ข้อมูลสมาชิก</h5>
                           <p>รหัสมาชิก : {userData.userId} </p>
                           <p>แพ็คเกจ : {userData.role} </p>
                           <p>สมัครมาในแพ็คเกจ : {userData.memberKeys} </p>
                           <p>
                              ตำแหน่ง :{' '}
                              {userData.position
                                 ? userData.position === 'Assistant'
                                    ? 'ผู้ช่วย'
                                    : userData.position === 'Director'
                                    ? 'ผอ'
                                    : userData.position === 'President'
                                    ? 'ประธาน'
                                    : ''
                                 : ''}
                           </p>
                     {userData.isBanned && (
                              <b className='text-danger'>
                              สถานะ : {userData.isBanned ? 'โดนแบน' : ''}
                           </b>
                     )}
                     <b>
                        อายุรหัส : {userData.expired}
                     </b>
                        </div>
                        <div>
                           <h5>ข้อมูลผู้แนะนำ</h5>
                           <p>
                              รหัสผู้แนะนำ : {userData.recommendedBy?.userId}{' '}
                           </p>
                           <p>รหัสอัพไลน์ : {userData.parentId?.userId} </p>
                        </div>
                     </div>
                  </>
               )}
            </div>
            <div className='d-flex justify-content-between'>
               <div className='mt-5 w-50'>
                  {userData.userId && (
                     <Form
                        form={form}
                        name='basic'
                        labelCol={{
                           span: 10,
                        }}
                        wrapperCol={{
                           span: 14,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete='off'
                        initialValues={{
                           name: userData.name,
                           firstName: userData.firstName,
                           lastName: userData.lastName,
                           beneficiary: userData.beneficiary,
                           pass: userData.pass,
                           createdAt: userData.createdAt,
                           cardId: userData.cardId,
                           passport: userData.passport,
                           passsport: userData.passsport,
                           address: userData.address?.address,
                           subdistrict: userData.address?.subdistrict,
                           district: userData.address?.district,
                           province: userData.address?.province,
                           postalCode: userData.address?.postalCode,
                           bank: userData.bankData?.bank,
                           bankAccount: userData.bankData?.bankAccount,
                           tel: userData?.contact?.tel,
                        }}
                     >
                        <div className='d-flex gap-3'>
                           <div>
                              {' '}
                              <Form.Item label='ชื่อที่แสดง' name='name'>
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                              <Form.Item label='ชื่อ' name='firstName'>
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                              <Form.Item label='นามสกุล' name='lastName'>
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                          {userData.cardId ? (<>
                           <Form.Item label='เลขบัตรประชาชน' name='cardId'>
                                 <Input
                                    disabled={isUpdatePassword}
                                    style={{ color: 'black' }}
                                 />
                              </Form.Item>
                          </>) : userData.passport ? (
                           <>
                               <Form.Item label='เลขบัตรประชาชน' name='passport'>
                                 <Input
                                    disabled={isUpdatePassword}
                                    style={{ color: 'black' }}
                                 />
                              </Form.Item>
                           </>
                          ) : userData.passsport ? (
                           <>
                               <Form.Item label='เลขบัตรประชาชน' name='passsport'>
                                 <Input
                                    disabled={isUpdatePassword}
                                    style={{ color: 'black' }}
                                 />
                              </Form.Item>
                           </>
                          ) : (
                           null
                          ) }
                              <Form.Item label='เบอรโทร' name='tel'>
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                              <h5>ที่อยู่</h5>
                              <Form.Item label='บ้านเลขที่' name='address'>
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                              <Form.Item label='ตำบล' name='subdistrict'>
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                              <Form.Item label='อำเภอ' name='district'>
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                              <Form.Item label='จังหวัด' name='province'>
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                              <Form.Item label='รหัสไปรษณี' name='postalCode'>
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                              ข้อมูลบัญชีธนาคาร
                              <Form.Item label='ธนาคาร' name='bank'>
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                              <Form.Item label='ชื่อบัญชี' name='bankName'>
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                              <Form.Item
                                 label='หมายเลขบัญชี'
                                 name='bankAccount'
                              >
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                           </div>
                           <div>
                              <Form.Item label='รหัสผ่าน' name='pass'>
                                 <Input
                                    onChange={(e) =>
                                       setPassword(e.target.value)
                                    }
                                    disabled={!isUpdatePassword}
                                 />
                              </Form.Item>
                              <Form.Item label='วันที่สมัคร'>
                                 <Input
                                    disabled
                                    value={moment(userData.createdAt).format(
                                       'DD/MM/YYYY'
                                    )}
                                 />
                              </Form.Item>
                              <Form.Item
                                 label='ผู้รับผลประโยชน์'
                                 name='beneficiary'
                              >
                                 <Input disabled={isUpdatePassword} />
                              </Form.Item>
                           </div>
                        </div>
                        {isUpdate && (
                           <>
                              {isUpdateAllUserByCardId && (
                                 <h5 className='text-danger'>
                                    <b>อัพเดททุกรหัสในเลขบัตร</b>
                                 </h5>
                              )}
                              <Button block type='primary' htmlType='submit'>
                                 {' '}
                                 Save{' '}
                              </Button>
                           </>
                        )}
                        {isUpdatePassword && (
                           <Button
                              block
                              type='primary'
                              onClick={() => handleUpdatePassword()}
                           >
                              {' '}
                              Update Password{' '}
                           </Button>
                        )}
                     </Form>
                  )}
               </div>
            {userInfo.isSuperAdmin ? (null) : (
                  <div className='d-flex flex-column gap-5'>
                  <Button
                     size='large'
                  className='second-shadow'
                        style={{background: '#ffc93c', color: 'black'}}
                     onClick={() => {
                        setIsUpdate(true);
                     }}
                  >
                     แก้ไขข้อมล
                  </Button>
                  {isUpdate && (
                     <Button
                        size='large'
                     className='second-shadow'
                     style={{background: '#fdd97c', color: 'black'}}
                        onClick={() => {
                           setIsUpdateAllUserByCardId(
                              (prevState) => !prevState
                           );
                        }}
                     >
                        {isUpdateAllUserByCardId
                           ? 'ยกเลิกการแก้ไขข้อมูลทุกรหัส'
                           : 'แก้ไขข้อมูลทุกรหัส'}
                     </Button>
                  )}
                  <Button
                     size='large'
                           className='second-shadow'
                           style={{background: '#272343', color: 'white'}}
                     onClick={() => {
                        setIsUpdatePassword((prevState) => !prevState);
                     }}
                  >
                     {isUpdatePassword
                        ? 'ยกเลิกการแก้ไขเปลี่ยนรหัสผ่าน'
                        : 'เปลี่ยนรหัสผ่าน'}
                  </Button>
                  {userData?.isBanned ? (
                     <Button
                     
                     size='large'onClick={() => handleUnBanUser(userData._id)}>
                         ยกเลิกแบนรหัสสมาชิก
                     </Button>
                  ) : (
                     <Button
                        size='large'
                              className='second-shadow'
                     style={{background: '#222831', color: 'white'}}
                      onClick={() => handleBanUser(userData._id)}>
                        แบนรหัสสมาชิก
                     </Button>
                  )}
                  <Button
                     size='large'
                        style={{background: '#5585b5', color: 'white'}}
                  onClick={() => handleIsCenter()}>
                     อัพเป็นเซนเตอร์
                  </Button>
                  <Button
                     size='large'
                     onClick={() => handleDeleteUser(userData._id)}
                     className='btn-danger second-shadow'
                  >
                     {' '}
                     ลบข้อมูลสมาชิก{' '}
                  </Button>
               </div>
            )}
            </div>
            <Modal
               width={850}
               title='ผลการค้นหาข้อมูล'
               open={isModalOpen}
               onOk={handleOk}
               onCancel={handleCancel}
            >
               <Table dataSource={users} columns={columns} />
            </Modal>
         </>
      </DefaultLayout>
   );
};

export default MemberRegistration;

import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefualtLayout';
import { useDispatch, useSelector } from 'react-redux';
import {
   addProdctAmount,
   getProducts,
   reset,
} from '../features/product/productSlice';
import {
   Button,
   Card,
   Col,
   ConfigProvider,
   Form,
   Input,
   InputNumber,
   Modal,
   Row,
   Table,
} from 'antd';

const StocksPage = () => {
   const dispatch = useDispatch();

   const [isIn, setIsIn] = useState(false);
   const [isOut, setIsOut] = useState(false);
   const [isAvialable, setIsAvialable] = useState(false);

   const [incProductModal, setIncProductModal] = useState(false);
   const [productId, setProductId] = useState('');
   const [productName, setProductName] = useState('');

   const { userInfo } = useSelector((state) => state.auth);
   const {
      products,
      isLoading,
      isError,
      isSuccess,
      getLoading,
      getSuccess,
      deleteSuccess,
      createSuccess,
   } = useSelector((state) => state.product);

   const products2 = products.filter((e) => e.type === 'Holder');

   // console.log('pp: ', products2);

   const summaryAvailablePerDay = {};
   const summaryInPerDay = {};
   const summaryOutPerDay = {};
   // Iterate over each product
   if (products2?.length >= 1) {
      products2?.forEach((product) => {
         // Iterate over each available record in the product
         product.available.forEach((record) => {
            const date = record.date.split('T')[0]; // Extract the date (YYYY-MM-DD)

            // Initialize the summary for this date if not already initialized
            if (!summaryAvailablePerDay[date]) {
               summaryAvailablePerDay[date] = [];
            }

            // Check if the product already exists in the summary for this date
            const existingProduct = summaryAvailablePerDay[date].find(
               (p) => p.name === product.name
            );
            if (existingProduct) {
               // If product exists, add the amount
               existingProduct.amount += record.amount;
            } else {
               // If product doesn't exist, create a new entry
               summaryAvailablePerDay[date].push({
                  name: product.name,
                  amount: record.amount,
               });
            }
         });
      });
   }
   // Convert the summaryPerDay object to an array for easier processing
   const ProductsAvilable = Object.keys(summaryAvailablePerDay).map((date) => ({
      date,
      products: summaryAvailablePerDay[date],
   }));

   if (products2?.length >= 1) {
      products2?.forEach((product) => {
         // Iterate over each available record in the product
         product.in.forEach((record) => {
            const date = record.date.split('T')[0]; // Extract the date (YYYY-MM-DD)

            // Initialize the summary for this date if not already initialized
            if (!summaryInPerDay[date]) {
               summaryInPerDay[date] = [];
            }

            // Check if the product already exists in the summary for this date
            const existingProduct = summaryInPerDay[date].find(
               (p) => p.name === product.name
            );
            if (existingProduct) {
               // If product exists, add the amount
               existingProduct.amount += record.amount;
            } else {
               // If product doesn't exist, create a new entry
               summaryInPerDay[date].push({
                  name: product.name,
                  amount: record.amount,
               });
            }
         });
      });
   }
   // Convert the summaryPerDay object to an array for easier processing
   const ProductsIn = Object.keys(summaryInPerDay).map((date) => ({
      date,
      products: summaryInPerDay[date],
   }));

   if (products2?.length >= 1) {
      products2?.forEach((product) => {
         // Iterate over each available record in the product
         product.out.forEach((record) => {
            const date = record.date.split('T')[0]; // Extract the date (YYYY-MM-DD)

            // Initialize the summary for this date if not already initialized
            if (!summaryOutPerDay[date]) {
               summaryOutPerDay[date] = [];
            }

            // Check if the product already exists in the summary for this date
            const existingProduct = summaryOutPerDay[date].find(
               (p) => p.name === product.name
            );
            if (existingProduct) {
               // If product exists, add the amount
               existingProduct.amount += record.amount;
            } else {
               // If product doesn't exist, create a new entry
               summaryOutPerDay[date].push({
                  name: product.name,
                  amount: record.amount,
               });
            }
         });
      });
   }
   // Convert the summaryPerDay object to an array for easier processing
   const ProductsOut = Object.keys(summaryOutPerDay).map((date) => ({
      date,
      products: summaryOutPerDay[date],
   }));

   const summaryAvilable = ProductsAvilable.map((day) => {
      const summaryObject = { date: day.date };
      day.products.forEach((product) => {
         summaryObject[product.name] = product.amount;
      });
      return summaryObject;
   });

   const summaryIn = ProductsIn.map((day) => {
      const summaryObject = { date: day.date };
      day.products.forEach((product) => {
         summaryObject[product.name] = product.amount;
      });
      return summaryObject;
   });

   const summaryOut = ProductsOut.map((day) => {
      const summaryObject = { date: day.date };
      day.products.forEach((product) => {
         summaryObject[product.name] = product.amount;
      });
      return summaryObject;
   });

   const handleTypeChange = (value) => {
      console.log(`selected ${value}`);
   };

   useEffect(() => {
      dispatch(getProducts());

      return () => {
         dispatch(reset());
      };
   }, [dispatch, isSuccess, createSuccess]);

   const columns = [
      { title: 'Date', dataIndex: 'date', key: 'date' },
      {
         title: 'Siamprai Plus',
         dataIndex: 'Siamprai Plus',
         key: 'Siamprai Plus',
      },
      {
         title: '24K Gold cleansing Gel',
         dataIndex: '24K Gold cleansing Gel',
         key: '24K Gold cleansing Gel',
      },
      {
         title: 'Age Therpist Reduces Skin Cream',
         dataIndex: 'Age Therpist Reduces Skin Cream',
         key: 'Age Therpist Reduces Skin Cream',
      },
      {
         title: 'Sunblock cold skin cream',
         dataIndex: 'Sunblock cold skin cream',
         key: 'Sunblock cold skin cream',
      },
      { title: 'Genevar', dataIndex: 'Genevar', key: 'Genevar' },
   ];

   const onFinish = (values) => {
      values._id = productId;
      console.log('v: ', values);
      dispatch(addProdctAmount(values));
   };

   return (
      <DefaultLayout>
         <div>
            <div className='d-flex justify-content-around'>
               <ConfigProvider
                  theme={{
                     token: {
                        // Seed Token
                        colorPrimary: '#00b96b',
                        

                        // Alias Token
                        colorBgContainer: '#f6ffed',
                     },
                  }}
               >
                  <Button
                     onClick={() => {
                        setIsIn(true);
                        setIsAvialable(false);
                        setIsOut(false);
                     }}
                     type='primary'
                  >
                     สินค้าเข้า
                  </Button>
                  <Button
                     onClick={() => {
                        setIsIn(false);
                        setIsAvialable(false);
                        setIsOut(true);
                     }}
                     style={{ backgroundColor: '#14213D', color: 'white' }}
                  >
                     สินค้าออก
                  </Button>
                  <Button
                     onClick={() => {
                        setIsIn(false);
                        setIsAvialable(true);
                        setIsOut(false);
                     }}
                     style={{ backgroundColor: '#14213D', color: 'white' }}
                  >
                     สินค้าคงเหลือ
                  </Button>
               </ConfigProvider>
            </div>
            <br />
            <div>
               {/* <Table columns={column} dataSource={products2} /> */}
               <Row gutter={10}>
                  {products2.map((e) => {
                     return (
                        <Col>
                           <Card
                              onClick={() => {
                                 setIncProductModal(true);
                                 setProductId(e._id);
                                 setProductName(e.name);
                              }}
                           >
                              <p> ชื่อสินค้า: {e.name}</p>
                           </Card>
                        </Col>
                     );
                  })}
               </Row>
            </div>
            <br />
            <hr />
            <div>
               <h5>
                  <b>รายการสินค้าเข้า ออก คงเหลือ</b>
               </h5>
               <Table
                  columns={columns}
                  dataSource={
                     isAvialable
                        ? summaryAvilable
                        : isIn
                        ? summaryIn
                        : isOut
                        ? summaryOut
                        : null
                  }
                  rowKey='date'
               />
            </div>
         </div>
         <Modal
            title='สินค้าเข้า'
            open={incProductModal}
            onCancel={() => setIncProductModal(false)}
            footer={false}
         >
            <div>
               <p> ชื่อสินค้า: {productName}</p>
               <Form
                  name='basic'
                  labelCol={{
                     span: 8,
                  }}
                  wrapperCol={{
                     span: 16,
                  }}
                  onFinish={onFinish}
                  autoComplete='off'
               >
                  <Form.Item name='amount' label='จำนวน'>
                     <InputNumber defaultValue={0} />
                  </Form.Item>
                  <Form.Item
                     wrapperCol={{
                        offset: 8,
                        span: 16,
                     }}
                  >
                     <Button type='primary' htmlType='submit'>
                        Save
                     </Button>
                  </Form.Item>
               </Form>
            </div>
         </Modal>
      </DefaultLayout>
   );
};

export default StocksPage;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import settingService from './settingService';
import Cookies from 'js-cookie';

const initialState = {
   settings: [],
   setting: {},
   isError: false,
   isSuccess: false,
   updateSuccess: false,
   isLoading: false,
   message: '',
};



export const getSetting = createAsyncThunk(
   'ewallet/getSetting',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await settingService.getSetting(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// CREATE SETTING
export const createSetting = createAsyncThunk(
   'setting/createSetting',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await settingService.createSetting(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const updateSetting = createAsyncThunk(
   'setting/updateSetting',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await settingService.updateSetting(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

const settingSlice = createSlice({
   name: 'setting',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(getSetting.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getSetting.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.settings = action.payload;
         })
         .addCase(getSetting.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateSetting.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateSetting.fulfilled, (state, action) => {
            state.isLoading = false;
            state.updateSuccess = true;
            state.setting = action.payload;
         })
         .addCase(updateSetting.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(createSetting.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createSetting.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.setting = action.payload;
         })
         .addCase(createSetting.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
 
   },
});

export const { reset } = settingSlice.actions;
export default settingSlice.reducer;

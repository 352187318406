import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import commissionService from './commissionService';
import Cookies from 'js-cookie';
const initialState = {
   commissions: [],
   commission: {},
   binary: {},
   recommendLine: [],
   reccommendCommsion: [],
   comMobile: [],
   mathcing: [],
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: '',
};



//ADMIN
export const getBonusByDates = createAsyncThunk(
   'commission/getBonusByDates',
   async (dates, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionService.getBonusByDates(dates,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);


export const getMatchingData = createAsyncThunk(
   'commission/getMatchingData',
   async (date,thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionService.getMatchingData(date,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
)

//ADMIN

export const getCommission = createAsyncThunk(
   'commission/getCommission',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionService.getCommission(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET SUM BINARY
export const getSumBinary = createAsyncThunk(
   'binary/getSumBinary',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionService.getSumBinary(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET RECOMMENDLINE
export const getRecLine = createAsyncThunk(
   'recommendLine/getRecommendLine',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionService.getRecLine(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET BY USERS
export const getByUsers = createAsyncThunk(
   'commission/getByUsers',
   async (users, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionService.getByUsers(users, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getRecommendCommission = createAsyncThunk(
   'commission/getRecommendCommission',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionService.getRecommendCommission(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getComMobile = createAsyncThunk(
   'commission/getComMobile',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionService.getComMobile(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

const commissionSlice = createSlice({
   name: 'commission',
   initialState,
   reducer: {
      reset: (state) => initialState,
   },

   extraReducers: (builder) => {
      builder
      .addCase(getMatchingData.pending, (state) => {
         state.isLoading = true;
      })
      .addCase(getMatchingData.fulfilled, (state, action) => {
         state.isLoading = false;
         state.isSuccess = true;
         state.mathcing = action.payload;
      })
      .addCase(getMatchingData.rejected, (state, action) => {
         state.isLoading = false;
         state.isError = true;
         state.message = action.payload;
         state.mathcing = null;
      })
         .addCase(getCommission.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCommission.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.commissions = action.payload;
         })
         .addCase(getCommission.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getSumBinary.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getSumBinary.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.binary = action.payload;
         })
         .addCase(getSumBinary.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getRecLine.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getRecLine.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.recommendLine = action.payload;
         })
         .addCase(getRecLine.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getComMobile.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getComMobile.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.comMobile.push(action.payload);
         })
         .addCase(getComMobile.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getByUsers.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getByUsers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.commissions = action.payload;
         })
         .addCase(getByUsers.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getRecommendCommission.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getRecommendCommission.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.reccommendCommsion = action.payload;
         })
         .addCase(getRecommendCommission.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getBonusByDates.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getBonusByDates.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.commissions = action.payload;
         })
         .addCase(getBonusByDates.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
         
   },
});

export const { reset } = commissionSlice.actions;
export default commissionSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import depositService from './depositService';
import Cookies from 'js-cookie';

const initialState = {
   deposits: [],
   centers: [],
   deposit: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   isUpdateSuccess: false,
   message: '',
};

// CREATE DEPOSIT
export const createDeposit = createAsyncThunk(
   'deposit/createDeposit',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.createDeposit(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const cancelDeposit =createAsyncThunk(
   'deposit/cancelDeposit',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.cancelDeposit(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);




// GET ALL DEPOSITS
export const getAllDeposits = createAsyncThunk(
   'deposit/getAllDeposits',
   async (page, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.getAllDeposits(page,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getCancelsDeposits = createAsyncThunk(
   'deposit/getCancelsDeposits',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.getCancelsDeposits(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET getTotalDepositRequest
export const getTotalDepositRequest = createAsyncThunk(
   'deposit/getTotalDepositRequest',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.getTotalDepositRequest(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getDepositDetail = createAsyncThunk(
   'deposit/getDepositDetail',
   async (dates, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.getDepositDetail(dates,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET ALL BY ID
export const getDepositsById = createAsyncThunk(
   'deposit/getDepositsById',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.getDepositsById(id,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// GET USER DEPOSIT BY ID
export const getUserDepositsById = createAsyncThunk(
   'deposit/getUserDepositsById',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.getUserDepositsById(id,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getCenterDeposits = createAsyncThunk(
   'deposit/getCenterDeposits',
   async (month, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.getCenterDeposits(month,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// UPDATE STATUS DEPOSITS
export const updateStatus = createAsyncThunk(
   'deposit/updateStatus',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.updateStatus(data,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const editDepostProducts = createAsyncThunk(
   'deposit/editDepostProducts',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.editDepostProducts(data,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const updateFreeGift = createAsyncThunk(
   'deposit/updateFreeGift',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.updateFreeGift(data,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const editFreeGift = createAsyncThunk(
   'deposit/editFreeGift',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.editFreeGift(data,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const approveDeposit = createAsyncThunk(
   'deposit/approveDeposit',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await depositService.approveDeposit(data,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

const depositSlice = createSlice({
   name: 'deposit',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(createDeposit.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createDeposit.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposit = action.payload;
         })
         .addCase(createDeposit.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getAllDeposits.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAllDeposits.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(getAllDeposits.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getCenterDeposits.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCenterDeposits.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.centers = action.payload;
         })
         .addCase(getCenterDeposits.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getCancelsDeposits.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCancelsDeposits.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(getCancelsDeposits.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getTotalDepositRequest.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getTotalDepositRequest.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(getTotalDepositRequest.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getDepositDetail.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getDepositDetail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(getDepositDetail.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getDepositsById.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getDepositsById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(getDepositsById.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getUserDepositsById.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getUserDepositsById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(getUserDepositsById.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateStatus.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateStatus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isUpdateSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(updateStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(editDepostProducts.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(editDepostProducts.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isUpdateSuccess = true;
         //   state.deposits = action.payload;
         })
         .addCase(editDepostProducts.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateFreeGift.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateFreeGift.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isUpdateSuccess = true;
         
         })
         .addCase(updateFreeGift.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(editFreeGift.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(editFreeGift.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isUpdateSuccess = true;
         
         })
         .addCase(editFreeGift.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(approveDeposit.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(approveDeposit.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isUpdateSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(approveDeposit.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(cancelDeposit.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(cancelDeposit.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.deposits = action.payload;
         })
         .addCase(cancelDeposit.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });

   },
});

export const { reset } = depositSlice.actions;
export default depositSlice.reducer;

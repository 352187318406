import { message } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// const API_URL = 'http://localhost:5000/api/users/';
// const API_URL2 = 'http://localhost:5000/api/usersbe/';

let API_URL = ''
let API_URL2 
if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:8000/api/users/';
   API_URL2 = 'http://localhost:8000/api/usersbe/';
} else {
   API_URL = '//api.admin-siamprai.com/api/users/';
   API_URL2 = '//api.admin-siamprai.com/api/usersbe/';

}


// GET ALL USERS
const getUsers = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL2, config);
   return response.data;
};


// UPDATE TO CENTER
const userToCenter = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.patch(API_URL2 + `updateToCenter/${id}`, {}, config);

   return res.data;
};

const getAllCenters = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(API_URL2 + `centers`, config);

   return res.data;
};

const getUserRegister = async (data,token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   console.log('data: ', data);
   
   const res = await axios.get(API_URL + `getUserRegister/${data.date}/${data.note}`, config);
   return res.data;
}

const userService = {
   getUsers,
   getUserRegister,
   userToCenter,
   getAllCenters,

};

export default userService;

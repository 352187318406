import axios from 'axios';

// const API_URL = '//env-3858542.proen.app.ruk-com.cloud/api/order/';

let API_URL = '';
if (process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:8000/api/order/';
} else {
   API_URL = '//api.admin-siamprai.com/api/order/';
}

const API = axios.create({ baseURL: '/api/order' });

API.interceptors.request.use((req) => {
   if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${
         JSON.parse(localStorage.getItem('userInfo')).token
      }`;
   }
   return req;
});

// Create  order
const createOrder = async (orderData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL, orderData, config);

   localStorage.removeItem('cartItems');
   window.location.reload();
   return response.data;
};

// Gets Orders
const getOrders = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL, config);
   return response.data;
};

// Gets getOrderById
const getOrderById = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + '/' + id, config);
   return response.data;
};

const getDataRegisterPerWeek = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getDataRegisterPerWeek', config);
   return response.data;
};

const getOrdersByUserId = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(
      API_URL + 'getOrdersByUserId/' + id,
      config
   );
   return response.data;
};

const getOrderByOrderNumber = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(
      API_URL + 'getOrderByOrderNumber/' + id,
      config
   );
   return response.data;
};

const cancelOrder = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };
console.log('token: ',token);
   const response = await axios.get(API_URL + 'cancelOrder/' + id, config);
   return response.data;
};

const orderService = {
   createOrder,
   getOrders,
   getOrderById,
   getOrdersByUserId,
   cancelOrder,
   getOrderByOrderNumber,
   getDataRegisterPerWeek
};

export default orderService;

import { Button, Statistic, Table, Row, Col, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { getSetting } from '../features/setting/settingSlice';
import DefaultLayout from '../components/DefualtLayout';
import {
   approveBonus,
   getAllBonus,
   reset,
   updateStatusToIsPaid,
} from '../features/adminFeatures/commission/commissionAdminSlice';
import { getUsersSumPerMonth } from '../features/adminFeatures/users/userAdminSlice';
import { toast } from 'react-toastify';

const { RangePicker } = DatePicker;

const ExportBonusPerDay = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const location = useLocation();
   const [loadingPage, setLoadingPage] = useState(true);

   const [isPV, setIsPV] = useState(false)

   const [newData2, setNewData2] = useState(null);

   const { users, isSuccess } = useSelector((state) => state.userAdmin);
   const { bonus, commissions, isLoading } = useSelector(
      (state) => state.commissionAdmin
   );
   const { settings } = useSelector((state) => state.setting);

   let bonusDesc = null;
   if (bonus?.length >= 1) {
      bonusDesc = [...bonus]; // Create a copy of the original array

      bonusDesc.sort((a, b) => new Date(b.date) - new Date(a.date));
   }

   let keyPrice = null;
   let transferFee = null;
   let tax = null;
   if (settings) {
      keyPrice = settings
         .filter((obj) => obj.name.includes('keyPrice'))
         .map((obj) => obj.number);

      transferFee = settings
         .filter((obj) => obj.name.includes('transferFee'))
         .map((obj) => obj.number);

      tax = settings
         .filter((obj) => obj.name.includes('tax'))
         .map((obj) => obj.number);

      transferFee.toString();
      tax.toString();
   }

   let transferFeeInt = parseInt(transferFee);
   let taxInt = parseInt(tax);

   let data = [];

   if (bonus.length >= 1) {
      data = bonus.map((e) => {
         return {
            ...e,
         };
      });
   }

   let newData = data.map((i, index) => {
      return {
         index,
         ...i,
         netTotalBonus:
            Math.floor(i.totalBonus) -
            Math.floor((i.totalBonus * taxInt) / 100 ),
      };
   });

   // Sum By Month

   // const reducedData = newData.reduce((accumulator, item) => {
   //    const month = new Date(item.date).toLocaleString('en-US', {
   //       month: 'long',
   //    });
   //    accumulator.push({
   //       [month]: (accumulator[month] || 0) + item.netTotalBonus,
   //    });
   //    return accumulator;
   // }, []);

   // const reducedDataEwallet = newData.reduce((accumulator, item) => {
   //    const month = new Date(item.date).toLocaleString('en-US', {
   //       month: 'long',
   //    });
   //    const existingMonth = accumulator.find(
   //       (obj) => Object.keys(obj)[0] === month
   //    );
   //    if (existingMonth) {
   //       existingMonth[month] += item.totalEwallet || 0;
   //    } else {
   //       accumulator.push({ [month]: item.totalEwallet || 0 });
   //    }
   //    return accumulator;
   // }, []);

   // const reducedDataRegister = newData.reduce((accumulator, item) => {
   //    const month = new Date(item.date).toLocaleString('en-US', {
   //       month: 'long',
   //    });
   //    const existingMonth = accumulator.find(
   //       (obj) => Object.keys(obj)[0] === month
   //    );
   //    if (existingMonth) {
   //       existingMonth[month] += item.totalAmountMemberRegister || 0;
   //    } else {
   //       accumulator.push({ [month]: item.totalAmountMemberRegister || 0 });
   //    }
   //    return accumulator;
   // }, []);

   // const reducedData2 = Object.entries(
   //    reducedData.reduce((accumulator, item) => {
   //       const month = Object.keys(item)[0];
   //       accumulator[month] = (accumulator[month] || 0) + item[month];
   //       return accumulator;
   //    }, {})
   // ).map(([month, amount]) => ({ month, amount }));
   // const reducedData2Array = [];
   // reducedData2Array.push(reducedData2);

   // const reducedData3 = Object.entries(
   //    reducedDataEwallet.reduce((accumulator, item) => {
   //       const month = Object.keys(item)[0];
   //       accumulator[month] = (accumulator[month] || 0) + item[month];
   //       return accumulator;
   //    }, {})
   // ).map(([month, amount]) => ({ month, amount }));
   // const reducedData3Array = [];

   // const reducedData4 = Object.entries(
   //    reducedDataRegister.reduce((accumulator, item) => {
   //       const month = Object.keys(item)[0];
   //       accumulator[month] = (accumulator[month] || 0) + item[month];
   //       return accumulator;
   //    }, {})
   // ).map(([month, amount]) => ({ month, amount }));
   // const reducedData4Array = [];

   // const bonusMonth = users.result;

   // let bonusMonthNum = null;
   // let ewalletMonthNum = null;
   // let registerMonthNum = null;

   // if (bonusMonth) {
    
   //    const currentMonth = new Date().toLocaleString('default', {
   //       month: 'long',
   //    });
    
   //    const currentMonthObject = reducedData2.reduce((result, obj) => {
   //       if (obj.month === currentMonth) {
   //          result = obj;
   //       }
   //       return result;
   //    }, null);
   //    const currentMonthObject2 = reducedData3.reduce((result, obj) => {
   //       if (obj.month === currentMonth) {
   //          result = obj;
   //       }
   //       return result;
   //    }, null);
   //    const currentMonthObject3 = reducedData4.reduce((result, obj) => {
   //       if (obj.month === currentMonth) {
   //          result = obj;
   //       }
   //       return result;
   //    }, null);

   //    bonusMonthNum = currentMonthObject;
   //    ewalletMonthNum = currentMonthObject2;
   //    registerMonthNum = currentMonthObject3;
   // }
   // Sum By Month END

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }
      dispatch(getAllBonus());
      dispatch(getSetting());
     // dispatch(getUsersSumPerMonth());

      return () => {
         dispatch(reset());
      };
   }, []);

   const headers = [
      { label: 'วันที่', key: 'date' },
      { label: 'เข้า eWallet', key: 'totalEwallet' },
      { label: 'ยอดโบนัส', key: 'totalBonus' },
   ];

   const headers2 = [
      { label: 'ชื่อ-สกุล', key: 'name', width: 30 },
      { label: 'รหัสสมาชิก', key: 'userId' },
      { label: 'เลขบัตรประชาชนหรือพาสปอร์ต', key: 'identification' },
      { label: 'ธนาคาร', key: 'bankData.bank' },
      { label: 'เลขบัญชี', key: 'bankData.bankAccount' },
      { label: 'รายได้สุทธิ', key: 'netSum', width: 40 },
   ];

   const columns = [
      {
         title: 'ลำดับ',
         dataIndex: 'depth',
         key: 'depth',
         render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
         title: 'วันที่',
         dataIndex: 'date',
         key: 'date',
         render: (record) => (
            <>
               <span> {moment(record).format('DD/MM/YYYY')} </span>
            </>
         ),
         sorter: (a, b) => new Date(a.date) - new Date(b.date),
      },
      {
         title: 'เข้า eWallet',
         dataIndex: 'totalEwallet',
         key: 'totalEwallet',
         render: (record) => (
            <span>
               {' '}
               <Statistic suffix='฿' value={record} />{' '}
            </span>
         ),
      },
      {
         title: 'ขาย eWallet',
         dataIndex: 'totalAmountMemberRegister',
         key: 'totalAmountMemberRegister',
         render: (record) => (
           <>
           {isPV ? (
            <>
            <span>
            {' '}
            <Statistic suffix='฿' value={record * 12} />{' '}
         </span>
            </>
           ) : (<>
            <span>
            {' '}
            <Statistic suffix='฿' value={record} />{' '}
         </span>
            </>)}
           </>
         ),
      },
      {
         title: 'ยอดโบนัส',
         dataIndex: 'netTotalBonus',
         key: 'netTotalBonus',
         render: (value, record) => (
            <>
               <Link
                  to={`/admin/exportBonusPerDayDetail/${record.date}`}
                  //</> state={{ date: record.date }}
               >
                  {/* <p>
                     <Statistic
                        style={{ color: 'blue !important' }}
                        suffix='฿'
                        value={value}
                     />{' '}
                  </p> */}
                  <span className='text-underline'>
                     {' '}
                     {value.toLocaleString()}{' '}
                  </span>
               </Link>
            </>
         ),
      },
      {
         title: 'รายการรอจ่าย',
         dataIndex: 'waitToPay',

         render: (waitToPay) => (
            <>
               <span>{waitToPay}</span>
            </>
         ),
      },
      {
         title: 'สถานะ',
         dataIndex: 'status',
         render: (value) => {
            if (value === 'รออนุมัติ') {
               return (
                  <div className='text-danger'>
                     <p>{value}</p>
                  </div>
               );
            } else {
               return (
                  <div className=''>
                     <p>{value}</p>
                  </div>
               );
            }
         },
      },
   ];

   let filteredDataByDate = null;
   let reduceTotalBonusDateFilter = null;
   let reduceTotalEwalletTransferDateFilter = null;
   let reduceTotalRegisterEwalletDateFilter = null;
   const onChange = (value, dateString) => {
      const sDate = dateString[0];
      const eDate = dateString[1];

      filteredDataByDate = newData.filter((e) => {
         const date = e.date;

         return (
            moment(date).isSameOrAfter(moment(sDate), 'day') &&
            moment(date).isSameOrBefore(moment(eDate), 'day')
         );
      });
      if (value === null) {
         setNewData2(null);
         window.location.reload();
      }
      setNewData2(filteredDataByDate);
   };

   if (newData2) {
      reduceTotalBonusDateFilter = newData2.reduce(
         (acc, item) => acc + item.netTotalBonus,
         0
      );

      reduceTotalEwalletTransferDateFilter = newData2.reduce(
         (accumulator, item) => {
            if (item.totalEwallet) {
               accumulator += item.totalEwallet;
            }
            return accumulator;
         },
         0
      );

      reduceTotalRegisterEwalletDateFilter = newData2.reduce(
         (accumulator, item) => {
            if (item.totalEwallet) {
               accumulator += item.totalEwallet;
            }
            return accumulator;
         },
         0
      );
   }
   const [selectedRows, setSelectedRows] = useState([]);



   const handleRowSelection = (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
   };

   const rowSelection = {
      selectedRowKeys: selectedRows.map((row) => row.index),
      onChange: handleRowSelection,
   };

   console.log('selectedRows:' , selectedRows);

   const handlerApproveBonus = () => {
      console.log('selectedRows: ', selectedRows);

      dispatch(approveBonus(selectedRows));

      toast.success('ทำรายการสำเร็จ');
   };

const handlerUpdateStatusToIsPaid = () => {
   dispatch(updateStatusToIsPaid(selectedRows))
}




   return (
      <DefaultLayout>
         {loadingPage || isLoading ? (
            <Loading />
         ) : (
            <>
               <div className='d-flex justify-content-between'>
                  <h5> รายงานสรุปเปอร์เซ็นต์การจ่ายของโบนัสในแผนรายวัน </h5>
                  <RangePicker onChange={onChange} allowClear  />{' '}
                  <Button>
                     <CSVLink
                        filename={'สรุปยอดรายงารสรุปการจ่ายของโบนัสรายวัน.csv'}
                        data={data}
                        headers={headers}
                     >
                        พิมย์รายงาน
                     </CSVLink>
                  </Button>
                  <Button 
                     onClick={() => {
                        setIsPV(prevState => !prevState);
                    }}
                  > ดูเป็นบาท </Button>
                  <Button
                  onClick={()=> handlerUpdateStatusToIsPaid()}
                  >
                     <CSVLink
                        filename={'สรุปยอดรายงารสรุปการจ่ายของโบนัสรายวัน.csv'}
                        data={commissions}
                        headers={headers2}
                     >
                        export โบนัสจ่ายรอบอาทิตย์
                     </CSVLink>
                  </Button>
                  <Button
                     className='btn btn-success'
                     onClick={() => handlerApproveBonus()}
                  >
                     อนุมัติโบนัส
                  </Button>
               </div>
               <br />
               <br />
               {newData2 !== null ? (
                  <>
                     <Table
                        summary={(newData) => {
                           let totalBonusFooter = 0;
                           let totalEwalletFooter = 0;
                           let totalAmountMemberRegisterFooter = 0;

                           newData.forEach(
                              ({
                                 netTotalBonus = 0,
                                 totalAmountMemberRegister = 0,
                                 totalEwallet = 0,
                              }) => {
                                 totalBonusFooter += netTotalBonus;
                                 totalEwalletFooter += totalEwallet;
                                 totalAmountMemberRegisterFooter +=
                                    totalAmountMemberRegister;
                              }
                           );

                           return (
                              <>
                                 <Table.Summary.Row>
                                    <Table.Summary.Cell>
                                       {' '}
                                       <b> รวม</b>{' '}
                                    </Table.Summary.Cell>{' '}
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell type='danger'>
                                       <b>
                                          {' '}
                                          <Statistic
                                             suffix='฿'
                                             value={totalEwalletFooter}
                                          />
                                       </b>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell type='danger'>
                                       <b>
                                          {' '}
                                          <Statistic
                                             suffix='฿'
                                             value={
                                                totalAmountMemberRegisterFooter
                                             }
                                          />
                                       </b>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell type='danger'>
                                       <b>
                                          {' '}
                                          <Statistic
                                             suffix='฿'
                                             value={totalBonusFooter}
                                          />
                                       </b>
                                    </Table.Summary.Cell>
                                 </Table.Summary.Row>
                              </>
                           );
                        }}
                        columns={columns}
                        dataSource={newData2}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.index}
                     />
                  </>
               ) : (
                  <>
                     <Table
                        summary={(newData) => {
                           let totalBonusFooter = 0;
                           let totalEwalletFooter = 0;
                           let totalAmountMemberRegisterFooter = 0;

                           newData.forEach(
                              ({
                                 netTotalBonus = 0,
                                 totalAmountMemberRegister = 0,
                                 totalEwallet = 0,
                              }) => {
                                 totalBonusFooter += netTotalBonus;
                                 totalEwalletFooter += totalEwallet;
                                 totalAmountMemberRegisterFooter +=
                                    totalAmountMemberRegister;
                              }
                           );

                           return (
                              <>
                                 <Table.Summary.Row>
                                    <Table.Summary.Cell>
                                       {' '}
                                       <b> รวม</b>{' '}
                                    </Table.Summary.Cell>{' '}
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell type='danger'>
                                       <b>
                                          {' '}
                                          <Statistic
                                             suffix='฿'
                                             value={totalEwalletFooter}
                                          />
                                       </b>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell type='danger'>
                                       <b>
                                          {' '}
                                         {isPV ? (
                                           <Statistic
                                           suffix='฿'
                                           value={
                                              totalAmountMemberRegisterFooter * 12
                                           }
                                        />
                                         ) : (
                                          <Statistic
                                          suffix='฿'
                                          value={
                                             totalAmountMemberRegisterFooter
                                          }
                                       />
                                         ) }
                                       </b>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell type='danger'>
                                       <b>
                                          {' '}
                                          <Statistic
                                             suffix='฿'
                                             value={totalBonusFooter}
                                          />
                                       </b>
                                    </Table.Summary.Cell>
                                 </Table.Summary.Row>
                              </>
                           );
                        }}
                        columns={columns}
                        dataSource={newData}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.index}
                     />
                  </>
               )}
            </>
         )}
      </DefaultLayout>
   );
};

export default ExportBonusPerDay;

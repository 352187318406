import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import orderService from './orderService';
import Cookies from 'js-cookie';

const initialState = {
   orders: [],
   order: {},
   dataRegisterPerWeek: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   isCancelSuccess: false,
   isCancelError: false,
   message: '',
};

export const cancelOrder = createAsyncThunk(
   'orders/cancelOrder',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await orderService.cancelOrder(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getOrdersByUserId = createAsyncThunk(
   'orders/getOrdersByUserId',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await orderService.getOrdersByUserId(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const getDataRegisterPerWeek = createAsyncThunk(
   'orders/getDataRegisterPerWeek',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await orderService.getDataRegisterPerWeek(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getOrderByOrderNumber = createAsyncThunk(
   'orders/getOrderByOrderNumber',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await orderService.getOrderByOrderNumber(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// Create new orders
export const createOrders = createAsyncThunk(
   'orders/createOrder',
   async (orderData, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await orderService.createOrder(orderData, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// Create new orders inhaler
export const createOrdersInhaler = createAsyncThunk(
   'orders/createOrderInhaler',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await orderService.createOrderInhaler(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// Get orders
export const getOrders = createAsyncThunk(
   'order/getAlls',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await orderService.getOrders(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// Get order by id
export const getOrderById = createAsyncThunk(
   'order/getById',
   async (id, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await orderService.getOrderById(id, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

const orderSlice = createSlice({
   name: 'order',
   initialState,
   reducer: {
      reset: (state) => initialState,
   },

   extraReducers: (builder) => {
      builder
         .addCase(getOrders.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getOrders.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.orders = action.payload;
         })
         .addCase(getOrders.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getOrdersByUserId.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getOrdersByUserId.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.orders = action.payload;
         })
         .addCase(getOrdersByUserId.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getOrderByOrderNumber.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getOrderByOrderNumber.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.order = action.payload;
         })
         .addCase(getOrderByOrderNumber.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getOrderById.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getOrderById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.orders = action.payload;
         })
         .addCase(getOrderById.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getDataRegisterPerWeek.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getDataRegisterPerWeek.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.dataRegisterPerWeek = action.payload;
         })
         .addCase(getDataRegisterPerWeek.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(createOrders.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createOrders.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.order = action.payload;
         })
         .addCase(createOrders.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.order = action.payload;
         })
         .addCase(createOrdersInhaler.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createOrdersInhaler.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.order = action.payload;
         })
         .addCase(createOrdersInhaler.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(cancelOrder.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(cancelOrder.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isCancelSuccess = true;
            state.order = action.payload;
            state.message = action.payload.message;
         })
         .addCase(cancelOrder.rejected, (state, action) => {
            state.isLoading = false;
            state.isCancelSuccess = false;
            state.isCancelError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = orderSlice.actions;
export default orderSlice.reducer;

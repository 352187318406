import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefualtLayout';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal, Radio, Space, Table } from 'antd';
import {
   cancelOrder,
   getOrderByOrderNumber,
   getOrdersByUserId,
   reset 
} from '../features/order/orderSlice';
import moment from 'moment';
import { toast } from 'react-toastify';
import CancelImg from '../assets/img/cancelled.png';
const { Search } = Input;

const OrderLists = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [userId, setUserId] = useState('');

   const [orderId, setOrderId] = useState('');
   const [searchBy, setSearchBy] = useState(null);

   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isModalOrdersOpen, setIsModalOrdersOpen] = useState(false);

   const { userInfo } = useSelector((state) => state.auth);

   const {
      orders,
      order: orderData,
      isLoading,
      isSuccess,
      message,
      isError,
      isCancelSuccess,
      isCancelError,
   } = useSelector((state) => state.order);

   const [order, setOrder] = useState({});

   useEffect(() => {
      if(userInfo.isSuperAdmin){
         navigate('/admin/dashboard')
      }
      if (isError) {
         toast.error(message);
      }
      if (searchBy === 1) {
         if (isSuccess) {
            setIsModalOrdersOpen(true);
         }
      }

      if(orders.length >= 1){
         if (isSuccess) {
            setIsModalOrdersOpen(true);
         }
      }

      if (isCancelSuccess) {
         toast.success(message);
      }
      if (isCancelError) {
         toast.error(message);
      }
      if (orderData) {
         setOrder(orderData);
      }

   }, [dispatch, isError, isSuccess, isCancelError, isCancelSuccess,searchBy,orders]);

   // useEffect(() => {

   //    return () => {
   //       dispatch(reset());
        
   //    };
   // },[dispatch])
 


   const handleOk = async () => {
      setIsModalOpen(false);
      if (searchBy === 1) {
         await dispatch(getOrdersByUserId(orderId));
      } else {
         await dispatch(getOrderByOrderNumber(orderId));
      }
      //  setIsModalOpen(false);
   };
   const handleCancel = () => {
      setIsModalOpen(false);
   };

   const order2 = order?.orderItems?.map((item, index) => ({
      ...item,
      key: index,
   }));

   const handleCancelOrder = (orderId) => {
      const confirm = window.confirm('ยืนยัน');

      if (confirm === true) {
         dispatch(cancelOrder(orderId));
    //     window.location.reload()
      } else {
       
      }
   };

   const columns = [
      {
         title: 'ลำดับ',
         dataIndex: 'depth',
         key: 'depth',
         render: (text, record, index) => (
            <span
               className='cursor-pointer'
               onClick={() => {
                  setOrder(record);
                  setIsModalOrdersOpen(false);
               }}
            >
               {index + 1}
            </span>
         ),
      },
      {
         title: 'หมายเลขอ้างอิง',
         dataIndex: 'orderNumber',
         render: (value, record) => (
            <span
               onClick={() => {
                  setOrder(record);
                  setIsModalOrdersOpen(false);
               }}
               className='cursor-pointer'
            >
               {value}
            </span>
         ),
      },
      {
         title: 'รหัสสมาชิก',
         dataIndex: 'user',
         render: (value, record) => (
            <span
               onClick={() => {
                  setOrder(record);
                  setIsModalOrdersOpen(false);
               }}
               className='cursor-pointer'
            >
               {value.userId}{' '}
            </span>
         ),
      },
      {
         title: 'วันที่ทำรายการ',
         dataIndex: 'createdAt',
         render: (value, record) => (
            <span
               onClick={() => {
                  setOrder(record);
                  setIsModalOrdersOpen(false);
               }}
               className='cursor-pointer'
            >
               {' '}
               {moment(value).format('DD/MM/YYYY')}{' '}
            </span>
         ),
      },
   ];

   const columns2 = [
      {
         title: 'ชื่อสินค้า',
         render: () => (
            <>
               {' '}
               <span>รหัสสยามไพรพลัส </span>{' '}
            </>
         ),
      },
      {
         title: 'จำนวน',
         render: () => <span> 1 รหัส </span>,
      },
      {
         title: 'ราคา',
         render: () => <span>{order.totalPv} </span>,
      },
   ];

   const handlerSearchByUserId = (value) => {
      
      dispatch(getOrdersByUserId(value));

      setOrderId('');
   };

   return (
      <DefaultLayout>
         <>
            <div>
            <div className='d-flex gap-2 mb-5 '>
               <Search
                  placeholder='ค้นหาโดยรหัสสมาชิก'
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                  allowClear
                  onSearch={(value)=> handlerSearchByUserId(value)}
               />
              
            </div>
               <div className='d-flex justify-content-between'>
                  <div>
                  <p>
                     สถานะ : {order.note}
                  </p>
                     <p>
                        รหัสสมาชิก : {order.user?.userId}{' '}
                        <span>{order.userName}</span>{' '}
                     </p>
                     <div>
                        <p>
                           {' '}
                           <span>ที่อยู่ :</span> {order.user?.address.address}
                        </p>
                        <p>
                           <span>อำเภอ :</span> {order.user?.address.district}
                        </p>
                        <p>
                           <span>ตำบล :</span> {order.user?.address.subdistrict}
                        </p>
                        <p>
                           <span>จังหวัด : </span>{' '}
                           {order.user?.address.province}
                        </p>
                        <p>
                           <span>รหัสไปรษณี : </span>{' '}
                           {order.user?.address.postalCode}
                        </p>
                     </div>
                  </div>
                  <div>
                     <p>
                        {' '}
                        หมายเลขอ้างอิง : <span>{order?.refNumber}</span>{' '}
                     </p>
                     <p>
                        เลขที่ใบเสร็จ : <span>{order?.orderNumber}</span>{' '}
                     </p>
                     <p>
                        วันที่ออกใบเสร็จ :{' '}
                        <span>
                           <>
                              {' '}
                              {order.createdAt &&
                                 moment(order.createdAt).format(
                                    'DD/MM/YYYY'
                                 )}{' '}
                           </>
                        </span>
                     </p>
                  </div>
               </div>
               <br />
               <hr />
               <div className='d-flex '>
                  {order._id && (
                     <div
                        style={{
                           backgroundImage:
                              order.status !== true ? `url(${CancelImg})` : '',
                           backgroundSize: 'cover',
                        }}
                        className='w-50'
                     >
                        <Table
                           columns={columns2}
                           dataSource={order2 ? order2 : null}
                        />
                        <hr />
                        <div className='d-flex justify-content-between '>
                           <div>
                              <p>
                                 อัพไลน์: {order.parentId?.userId}{' '}
                                 <span>{order.parentId?.name}</span>{' '}
                              </p>
                              <p>
                                 ผู้แนะนำ: {order.recommendedBy?.userId}{' '}
                                 <span>{order.recommendedBy?.name}</span>
                              </p>
                              <p>
                                 คีย์โดย: {order.userOrder?.userId}{' '}
                                 <span>{order.userOrder?.name}</span>
                              </p>
                           </div>
                           <div className=''>
                              <p>ราคา PV : {order.totalPv} PV</p>{' '}
                              <p>
                                 ราคาสินค้า :{' '}
                                 {order.totalPrice -
                                    (order.totalPrice * 0.07).toFixed(2)}{' '}
                                 บาท{' '}
                              </p>
                              <p>
                                 VAT 7% : {(order.totalPrice * 0.07).toFixed(2)}{' '}
                                 บาท
                              </p>
                              <p>ราคาสินค้าสุทธิ : {order.totalPrice} บาท</p>
                              <p></p>
                           </div>
                        </div>
                     </div>
                  )}
                  <div
                     style={{ marginLeft: 'auto' }}
                     className='d-flex flex-column gap-5'
                  >
                     {/* <Button>เพิ่มรายการ</Button>
                     <Button>แก้ไขรายการ</Button> */}
                     <Button onClick={() => setIsModalOpen(true)}>
                        ค้นหารายการ
                     </Button>

                     <Button
                        className='btn btn-danger'
                        onClick={() => handleCancelOrder(order._id)}
                     >
                        ยกเลิกรายการ
                     </Button>
                  </div>
               </div>
            </div>
            <Modal
               title='ค้นหารายการ'
               open={isModalOpen}
               onOk={handleOk}
               onCancel={handleCancel}
            >
               <Space direction='vertical' size='middle'>
                  <Input onChange={(e) => setOrderId(e.target.value)} />

                  <Radio.Group onChange={(e) => setSearchBy(e.target.value)}>
                     <Radio value={1}>ค้นหาโดยรหัสสมาชิก</Radio>
                     <Radio value={2}>ค้นหาโดยหมายเลขรายการสั่งซื้อ</Radio>
                  </Radio.Group>
               </Space>
            </Modal>
            <Modal
               title='รายการสั่งซื้อ'
               open={isModalOrdersOpen}
               footer={false}
               onCancel={() => {
                  setIsModalOrdersOpen(false);
                  window.location.reload()
               }}
            >
               <Table columns={columns} dataSource={orders} />
            </Modal>
         </>
      </DefaultLayout>
   );
};

export default OrderLists;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Layout, Menu, Image, Dropdown, Space, Row, Col } from 'antd';
import {
   AiOutlineHome,
   AiOutlineMenuFold,
   AiOutlineMenuUnfold,
   AiOutlineLogout,
   AiOutlineBranches,
} from 'react-icons/ai';

import { logout } from '../features/auth/authSlice';
import {
 
   BiExport,
} from 'react-icons/bi';
import {  HiOutlineShare } from 'react-icons/hi';
import {

   FaListUl,
   FaUser,
} from 'react-icons/fa';
import styled from 'styled-components';
import {
   MdOutlineSettings,
} from 'react-icons/md';
import Logo from '../assets/img/BlueLogo.png';


import { useMediaQuery } from 'react-responsive';

import admin from '../assets/icon/user1.png';
import { RiLuggageDepositFill } from 'react-icons/ri';

import { TbReportAnalytics, TbReportMoney } from 'react-icons/tb';
import { getAdminCenterPV, reset } from '../features/ewallet/ewalletSlice';
import commaNumber from 'comma-number';

const { Header, Sider, Content } = Layout;

const DefaultLayoutKh = (props) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { userInfo } = useSelector((state) => state.auth);
   const { ewallet } = useSelector((state) => state.ewallet);

   const cart = useSelector((state) => state.cart);

   const { cartItems } = cart;

   const [collapsed, setCollapsed] = useState(false);
   const toggleCollapsed = () => {
      setCollapsed(!collapsed);
   };

   const isMobile = useMediaQuery({ maxWidth: 750 });

   useEffect(() => {
   
  
      dispatch(getAdminCenterPV());

      if (isMobile) {
         setCollapsed(!collapsed);
      }

      return () => {
         dispatch(reset());
      };
   }, [dispatch, isMobile, cartItems, dispatch]);

   const handleResize = () => {
      if (window.innerWidth < 520 || window.innerWidth < 780) {
         setCollapsed(true);
      } else {
         setCollapsed(false);
      }
   };

   // create an event listener
   useEffect(() => {
      window.addEventListener('resize', handleResize);
   });

   const logoutHandler = () => {
      dispatch(logout());
      navigate('/login');
   };

   const menu = (
      <Menu
         items={[
            {
               key: '1',
               label: (
                  <>
                     <Row>
                        <Col span={8}>
                           {' '}
                           <Image
                              style={{
                                 marginTop: '20px',
                              }}
                              src={admin}
                              preview={false}
                              width={55}
                           />
                     
                        </Col>
                        <Col span={14}>
                           <p> {userInfo.username}</p>
                           <p>รหัส:{userInfo.userId} </p>
                        </Col>
                     </Row>
                     <hr />
                  </>
               ),
            },

            {
               key: '5',
               danger: true,
               label: (
                  <>
                     <p
                        onClick={logoutHandler}
                        style={{
                           borderTop: '1px solid gray',
                           marginBottom: '10px !important',
                        }}
                     >
                        Logout
                     </p>
                     <hr />
                  </>
               ),
            },
         ]}
      />
   );

   return (
      <div>
         <Layout>
            <Sider
               trigger={null}
               breakpoint='md'
               collapsedWidth={0}
               collapsible
               collapsed={collapsed}
            >
               <div className='logo p-2 mb-2'>
                  <Image src={Logo} preview={false} />
               </div>
               <Menu
                  defaultSelectedKeys={window.location.pathname}
                  defaultOpenKeys={window.location.pathname}
                  mode='inline'
               >
                  <Menu.Item key='/admin/dashboard/kh' icon={<AiOutlineHome />}>
                     <Link to='/admin/dashboard/kh'>Dashboard</Link>
                  </Menu.Item>
                  <Menu.Item key='/admin/memberRegistration/kh' icon={<FaUser />}>
                     <Link to='/admin/memberRegistration/kh'>Members</Link>
                  </Menu.Item>
                  <Menu.Item
                     key='/admin/stocksHistories'
                     icon={<TbReportAnalytics />}
                  >
                     <Link to='/admin/stocksHistories/kh'>Sales</Link>
                  </Menu.Item>
               

                  <Menu.Item
                     key='/admin/exportBonusSelectDayKhmer/kh'
                     icon={<TbReportMoney />}
                  >
                     <Link to='/admin/exportBonusSelectDayKhmer/kh'>
                        commissions
                     </Link>
                  </Menu.Item>

                  <Menu.Item
                     key='/admin/summaryCommissionUsersPerMonth/kh'
                     icon={<TbReportMoney />}
                  >
                     <Link to='/admin/summaryCommissionUsersPerMonth/kh'>
                     SummaryCommissionUsersPerMonth
                     </Link>
                  </Menu.Item>

                  <Menu.Item
                     key='/admin/deposits/kh'
                     icon={<RiLuggageDepositFill />}
                  >
                     <Link to='/admin/deposits/kh'> Deposits</Link>
                  </Menu.Item>

                  <Menu.Item key='/admin/orderLists/kh' icon={<BiExport />}>
                     <Link to='/admin/orderLists/kh'> OrderLists </Link>
                  </Menu.Item>

                  <Menu.Item key='/admin/centers/kh' icon={<AiOutlineBranches />}>
                     <Link to='/admin/centers/kh'> Centers </Link>
                  </Menu.Item>
                  {userInfo.isSuperAdmin ? null : (
                     <Menu.Item key='/admin/productlist/kh' icon={<FaListUl />}>
                        <Link to='/admin/productlist/kh'> Products </Link>
                     </Menu.Item>
                  )}

                  <Menu.Item key='/admin/setting/kh' icon={<MdOutlineSettings />}>
                     <Link to='/admin/setting/kh'>Setting</Link>
                  </Menu.Item>
                  {/* <Menu.Item key='/resetpassword' icon={<FaKey />}>
                     <Link to='/resetpassword'>เปลี่ยนรหัสผ่าน</Link>
                  </Menu.Item> */}
                  <Menu.Item
                     key='/logout'
                     icon={<AiOutlineLogout />}
                     onClick={logoutHandler}
                  >
                     Logout
                  </Menu.Item>
               </Menu>
            </Sider>

            <Layout className='site-layout'>
               <Header
                  className='d-flex site-layout-background'
                  style={{ padding: 10 }}
               >
                  {React.createElement(
                     collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
                     {
                        className: 'trigger',
                        onClick: toggleCollapsed,
                     }
                  )}

                  <Space>
                     {/* <h5>
                        <AiOutlineShoppingCart />
                     </h5>
                     <span className='text-danger'>
                        <p>{cart.cartItems.length}</p>{' '}
                     </span> */}

                     {/* <Dropdown overlay={menuCart}>
                        <a onClick={(e) => e.preventDefault()}>
                           <Badge count={cart.cartItems.length}>
                              <Avatar
                                 style={{ color: 'black' }}
                                 shape='circle'
                                 icon={<AiOutlineShoppingCart />}
                                 size='large'
                              />
                           </Badge>
                        </a>
                     </Dropdown> */}

                     <span>
                        <b>PV : {commaNumber(ewallet.pv)} </b>
                     </span>

                     <Dropdown overlay={menu}>
                        <a onClick={(e) => e.preventDefault()}>
                           <h4 className='mx-2 my-1'>
                              {' '}
                              {/* <Image src={user} preview={false} width={24} />
                              <Image src={user1} preview={false} width={24} />
                              <Image src={user2} preview={false} width={24} /> */}
                              <>
                                 {' '}
                                 <Image
                                    src={admin}
                                    preview={false}
                                    width={24}
                                 />
                              </>
                           </h4>
                        </a>
                     </Dropdown>
                  </Space>
               </Header>
               <div className=''>
                  {' '}
                  <Content
                     className='site-layout-background'
                     style={{
                        margin: '10px',
                        padding: 24,
                        minHeight: '80vh',
                     }}
                  >
                     {props.children}
                  </Content>
               </div>
            </Layout>
         </Layout>
      </div>
   );
};

export default DefaultLayoutKh;

import { Button, Statistic, Table, Row, Col, DatePicker, Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { getSetting } from '../features/setting/settingSlice';
import DefaultLayout from '../components/DefualtLayout';
import {
   approveBonus,
   getAllBonus,
   reset,
   updateStatusToIsPaid,
} from '../features/adminFeatures/commission/commissionAdminSlice';
import { getUsersSumPerMonth } from '../features/adminFeatures/users/userAdminSlice';
import { toast } from 'react-toastify';
import { getBonusByDates } from '../features/commission/commissionSlice';
import { getDepositDetail, reset as resetDeposit } from '../features/deposit/depositSlice';
import commaNumber from 'comma-number';
import { getProducts } from '../features/product/productSlice';

const { RangePicker } = DatePicker;

const SpecialBonusPage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const { deposits } = useSelector((state) => state.deposit);
   const { products } = useSelector((state) => state.product);


   const { userInfo } = useSelector((state) => state.auth);
   const { bonus, commissions, isLoading } = useSelector(
      (state) => state.commissionAdmin
   );

   const [freeGift ,setFreeGift] = useState([])
   const [centerSelect, setCenterSelect] = useState({})
   const [modalGift, setModalGif] = useState(false);

   useEffect(() => {
      return () => {
        
         dispatch(resetDeposit())
      };
   },[dispatch])

   useEffect(() => {
      dispatch(getProducts());

      // if(!userInfo.isSuperAdmin){
      //    navigate('/admin/dashboard')
      // }

      return () => {
         dispatch(reset());
         dispatch(resetDeposit())
      };
   }, [dispatch]);

   let products2 = null;

   if (deposits.length >= 1) {
      products2 = products.filter((e) => e.type === 'Holder');
   }

   const summaryPerDay = {};
   // Iterate over each product
   if (products2?.length >= 1) {
      products2?.forEach((product) => {
         // Iterate over each available record in the product
         product.available.forEach((record) => {
            const date = record.date.split('T')[0]; // Extract the date (YYYY-MM-DD)

            // Initialize the summary for this date if not already initialized
            if (!summaryPerDay[date]) {
               summaryPerDay[date] = [];
            }

            // Check if the product already exists in the summary for this date
            const existingProduct = summaryPerDay[date].find(
               (p) => p.name === product.name
            );
            if (existingProduct) {
               // If product exists, add the amount
               existingProduct.amount += record.amount;
            } else {
               // If product doesn't exist, create a new entry
               summaryPerDay[date].push({
                  name: product.name,
                  amount: record.amount,
               });
            }
         });
      });
   }
   // Convert the summaryPerDay object to an array for easier processing
   const summaryArray = Object.keys(summaryPerDay).map((date) => ({
      date,
      products: summaryPerDay[date],
   }));

   let filteredDataByDate = null;
   const onChange = (value, dateString) => {
      const sDate = dateString[0];
      const eDate = dateString[1];

      let dates = {};
      dates.eDate = eDate;
      dates.sDate = sDate;

      if (eDate || sDate) {
         dispatch(getDepositDetail(dates));
      }
   };

   const processData = (data) => {
      const processedData = [];
      const seenDates = new Set();

      data?.forEach((item) => {
         const date = item.createdAt.split('T')[0]; // Only consider the date part

         if (seenDates.has(date)) {
            processedData.push({ ...item, showDate: false });
         } else {
            seenDates.add(date);
            processedData.push({ ...item, showDate: true });
         }
      });

      return processedData;
   };
   const processedData = processData(deposits);

   const groupedData = processedData.reduce((acc, curr) => {
      const date = curr.createdAt.split('T')[0]; // Extract date from createdAt
      if (!acc[date]) {
         acc[date] = [];
      }
      acc[date].push(curr);
      return acc;
   }, {});

   // Now calculate total per day
   const totalsPerDay = Object.keys(groupedData).map((date) => {
      const totalAmount = groupedData[date].reduce(
         (total, item) => total + item.amount,
         0
      );
      return { date, totalAmount };
   });

   const depFeeGift = deposits.filter((e) => e.freeGift?.length >= 1);

   const processData2 = (data) => {
      const processedData = [];
      const seenDates = new Set();

      data?.forEach((item) => {
         const date = item.createdAt.split('T')[0]; // Only consider the date part

         if (seenDates.has(date)) {
            processedData.push({ ...item, showDate: false });
         } else {
            seenDates.add(date);
            processedData.push({ ...item, showDate: true });
         }
      });

      return processedData;
   };
   const depFeeGift2 = processData2(depFeeGift);


   const columns2 = [
    
      // {
      //    title: 'รายการ',
      //    dataIndex: 'user',
      //    render: (value, record) => (
      //       <>
      //          <span
                  
      //          >
      //             {value ? value.name : record.name}
      //          </span>
      //          ,
      //       </>
      //    ),
      // },
      {
         title: 'สินค้า',
         children: [
            {
               title: 'สยามไพร',
               dataIndex: 'amount',
               // render: (products) => {
               //    const product = products?.find(
               //       (p) => p.name === 'Siamprai Plus'
               //    );

               //    return product ? commaNumber(product.amount) : 0;
               // },
            },
            {
               title: 'เจนเอวา',
               dataIndex: 'freeGift',
               // render: (products) => {
               //    if (products.length >= 1) {
               //       const product = products?.find(
               //          (p) => p.name === 'Genevar'
               //       );
               //       return product ? commaNumber(product.amount) : 0;
               //    }
               // },
            },
            {
               title: 'เจลล้างหน้า',
               dataIndex: 'freeGift',
            },
            {
               title: 'ครีมบำรุง',
               dataIndex: 'freeGift',
            },
            {
               title: 'กันแดด',
               dataIndex: 'freeGift',
            },
    
         ],
      },
   ];

   const columns = [
      {
         title: 'ว/ด/ป',
         dataIndex: 'createdAt',
         render: (value, record) =>
            record.showDate
               ? moment(record.createdAt).format('DD/MM/YYYYY')
               : '',
         sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      },
      {
         title: 'รายการ',
         dataIndex: 'user',
         render: (value, record) => (
            <>
               <span
                  onClick={() => {
                     setCenterSelect(value.name)
                     setFreeGift(record?.freeGift)

                     console.log('das: ', record?.freeGift);
             //
             
             setModalGif(true)
                     // if(record?.freeGift?.length >= 1){
                     //    setModalGif(true);
                     // }
                  }}
                  className='cursor-pointer'
                  style={{
                     color: record?.freeGift?.length >= 1 ? 'red' : ''
                  }}
               >
                  {value ? value.name : record.name}
               </span>
               -    <span>    {value ? value.userId : record.userId}</span>
               
            </>
         ),
      },
      {
         title: 'สินค้า',
         children: [
            {
               title: 'สยามไพร',
               dataIndex: 'totalProduct',
               render: (products) => {
                  const product = products?.find(
                     (p) => p.name === 'Siamprai Plus'
                  );

                  return product ? commaNumber(product.amount) : 0;
               },
            },
            {
               title: 'เจนเอวา',
               dataIndex: 'totalProduct',
               render: (products) => {
                  if (products.length >= 1) {
                     const product = products?.find(
                        (p) => p.name === 'Genevar'
                     );
                     return product ? commaNumber(product.amount) : 0;
                  }
               },
            },
            {
               title: 'เจลล้างหน้า',
               dataIndex: 'totalProduct',
               render: (products) => {
                  if (products.length >= 1) {
                     const product = products?.find(
                        (p) => p.name === '24K Gold cleansing Gel'
                     );
                     return product ? commaNumber(product.amount) : 0;
                  }
               },
            },
            {
               title: 'ครีมบำรุง',
               dataIndex: 'totalProduct',
               render: (products) => {
                  if (products.length >= 1) {
                     const product = products?.find(
                        (p) => p.name === 'Age Therpist Reduces Skin Cream'
                     );
                     return product ? commaNumber(product.amount) : 0;
                  }
               },
            },
            {
               title: 'กันแดด',
               dataIndex: 'totalProduct',
               render: (products) => {
                  if (products.length >= 1) {
                     const product = products?.find(
                        (p) => p.name === 'Sunblock cold skin cream'
                     );
                     return product ? commaNumber(product.amount) : 0;
                  }
               },
            },
         ],
      },
      {
         title: 'เป็นเงิน',
         dataIndex: 'amount',
         render: (value) => <span>{commaNumber(value)}</span>,
      },
      {
         title: 'PV',
         dataIndex: 'totalPv',
         render: (value) => <span>{commaNumber(value)}</span>,
      },
   ];

   const productTotalsPerDay = {};

   const tableData = processedData.reduce((acc, curr, index, array) => {
      const currentDate = curr.createdAt.split('T')[0];
      const nextDate =
         index < array.length - 1
            ? array[index + 1].createdAt.split('T')[0]
            : null;
   
      acc.push(curr);
   
      if (!productTotalsPerDay[currentDate]) {
         productTotalsPerDay[currentDate] = {
            products: {},
            totalAmount: 0,
            totalPv: 0,
            totalFreeGiftAmounts: {},
         };
      }
   
      // Sum amounts and PVs for the day
      productTotalsPerDay[currentDate].totalAmount += curr.amount;
      productTotalsPerDay[currentDate].totalPv += curr.totalPv;
   
      curr.totalProduct.forEach((product) => {
         if (!productTotalsPerDay[currentDate].products[product.name]) {
            productTotalsPerDay[currentDate].products[product.name] = 0;
         }
         productTotalsPerDay[currentDate].products[product.name] += product.amount;
      });
   
      // Sum the freeGift amounts for the day
      curr.freeGift.forEach((gift) => {
         if (!productTotalsPerDay[currentDate].totalFreeGiftAmounts[gift.name]) {
            productTotalsPerDay[currentDate].totalFreeGiftAmounts[gift.name] = 0;
         }
         productTotalsPerDay[currentDate].totalFreeGiftAmounts[gift.name] += gift.amount;
      });
   
      if (currentDate !== nextDate) {
         const productTotals = Object.entries(
            productTotalsPerDay[currentDate].products
         ).map(([name, amount]) => {
            // Sum product amount with free gift amount for Siamprai Plus
            if (name === 'Siamprai Plus') {
               amount += productTotalsPerDay[currentDate].totalFreeGiftAmounts[name] || 0;
            }
            return { name, amount };
         });
   
         acc.push({
            isSummaryRow: true,
            createdAt: currentDate,
            showDate: true,
            name: 'รวม',
            totalProduct: productTotals,
            amount: productTotalsPerDay[currentDate].totalAmount,
            totalPv: productTotalsPerDay[currentDate].totalPv,
         });
      }
   
      return acc;
   }, []);

   const updatedTableData = [...tableData];

   summaryArray.forEach((newEntry) => {
      const newRow = {
         isFirstRow: true,
         showDate: true,
         name: 'ยกยอด',
         createdAt: newEntry.date,
         totalProduct: newEntry.products,
      };

      // Find the index of the first entry for the corresponding date
      const index = updatedTableData.findIndex((entry) => {
         return entry.createdAt && entry.createdAt.startsWith(newEntry.date);
      });

      if (index !== -1) {
         updatedTableData.splice(index, 0, newRow);
      } else {
         // If the date is not found in the original tableData, add the new entry
         //  updatedTableData.push(newRow);
      }
   });

   const rowClassName = (record) => {
      if (record.isSummaryRow) {
         return 'summary-row';
      } else if (record.isFirstRow) {
         return 'first-row';
      } else {
         return '';
      }
   };
   const disabledDate = (current) => {
      return current && current > moment().endOf('day');
   };

   //console.log('freeGift: ', freeGift);

   return (
      <DefaultLayout>
         <div className='d-flex justify-content-between'>
            <RangePicker onChange={onChange} allowClear   disabledDate={disabledDate} />{' '}
         </div>

         <div className=''>
            <Table
               style={{
                  width: '1000px',
               }}
               columns={columns}
               dataSource={updatedTableData}
               rowClassName={rowClassName}
               pagination={false}
               rowKey='_id'
            />
         </div>
         <Modal title='รายการของแถม'
         onCancel={()=> setModalGif(false)}
         open={modalGift} width={1000}>
          <>
          <div>
            <h5>
               <b>
                  {centerSelect}
               </b>
            </h5>
          <Table
               style={{
                  width: '800px',
               }}
               pagination={false}
               columns={columns2}
               dataSource={freeGift}
            />
          </div>
          </>
         </Modal>
      </DefaultLayout>
   );
};
export default SpecialBonusPage;

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import centerReducer from './features/center/centerSlice';
import userReducer from './features/users/usersSlice';
import cartReducer from './features/cartSlice';
import orderReducer from './features/order/orderSlice';
import commissionReducer from './features/commission/commissionSlice';
import ewalletReducer from './features/ewallet/ewalletSlice';
import settingReducer from './features/setting/settingSlice';
import productReducer from './features/product/productSlice';
import depositReducer from './features/deposit/depositSlice';

import commissionAdminReducer from './features/adminFeatures/commission/commissionAdminSlice'
import userAdminReducer from './features/adminFeatures/users/userAdminSlice'
import AdminReducer from './features/admin/userAdminSlice'

import khmerReducer from './features/khmer/khmerSlice'


export const store = configureStore({
   reducer: {
      auth: authReducer,
      centers: centerReducer,
      user: userReducer,
      cart: cartReducer,
      order: orderReducer,
      ewallet: ewalletReducer,
      commission: commissionReducer,
      setting: settingReducer,
      product: productReducer,
      deposit: depositReducer,

      khmer : khmerReducer,

      userAdmin: userAdminReducer,
      admin: AdminReducer,
      commissionAdmin: commissionAdminReducer,
   },
});

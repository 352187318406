import React, { useEffect } from 'react'
import DefaultLayout from '../components/DefualtLayout'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDirector, getUsersPower } from '../features/adminFeatures/users/userAdminSlice';
import { Button } from 'antd';

const MemberPower = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { userInfo } = useSelector((state) => state.auth);
    const { users, isLoading, isSuccess, deleteIsSuccess } = useSelector(
        (state) => state.user
     );

     useEffect(()=> {

        
      if (!userInfo.isAdmin) {
        navigate('/login');
     }

     //   dispatch(getUsersPower())
     },[dispatch])
 
     const getAssistant = () => {
        dispatch(getUsersPower())
     }

     const CallGetDirector = () => {
        dispatch(getDirector())
     }

     const columns  = [
        {
            title: 'ชื่อที่แสดง',
            dataIndex: 'name',

        },
        {
            title: 'รหัส',
            dataIndex: 'userId',             
        },
        {

        }
     ]


  return (
   <DefaultLayout>
    <div>
        <Button onClick={()=> getAssistant()}  >Assistant</Button>
        <Button onClick={()=> CallGetDirector()}>Director</Button>
        <Button>President</Button>
    </div>
    <br />
    <div>
        <Button>Ready fo Assistant</Button>
    </div>
   </DefaultLayout>
  )
}

export default MemberPower
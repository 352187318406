import React, { useEffect, useState } from 'react';
import { Row, Modal, Input, Form, Button } from 'antd';

import { useNavigate } from 'react-router-dom';

import Loading from '../components/Loading';
import DefaultLayout from '../components/DefualtLayout';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../features/auth/authSlice';
import { editRecommendUser } from '../features/adminFeatures/users/userAdminSlice';
import { reset as resetDeposits } from '../features/deposit/depositSlice';
import { toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';

import {
   XAxis,
   YAxis,
   Tooltip,
   ResponsiveContainer,
   AreaChart,
   Area,
} from 'recharts';
import {
   getDataForIncomeGraph,
   reset as resetCommission,
} from '../features/adminFeatures/commission/commissionAdminSlice';
import TodaySale from '../components/TodaySale';
import { Centers } from '../components/Centers';
import commaNumber from 'comma-number';
import Products from '../components/Products';
import ExportUserRegister from '../components/ExportUserRegister';
const Home = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [isLoading, setIsLoading] = useState(true);
   const { userInfo } = useSelector((state) => state.auth);
   const { isError, message, isSuccess } = useSelector(
      (state) => state.userAdmin
   );
   const { allData } = useSelector((state) => state.commissionAdmin);

   const [isEditRecom, setIsEditRecom] = useState(false);
   const [modalIsError, setModalIsError] = useState(false);
   const [modalIsConfirm, setModalIsConfirm] = useState(false);

   const [DataUpdateRecom, setDataUpdateRecom] = useState(null);

   useEffect(() => {
      if (!userInfo.isAdmin) {
         dispatch(logout());
         navigate('/');
      }

      if (isLoading) {
         setTimeout(() => {
            setIsLoading(false);
         }, 1500);
      }
   }, []);

   useEffect(() => {
      if (isError) {
         setModalIsError(true);
      }
      if (isSuccess) {
         toast.success('ทำรายการสำเร็จ');
      }

      dispatch(getDataForIncomeGraph());

      return () => {
         dispatch(resetCommission());
         dispatch(resetDeposits());
      };
   }, [isError, isSuccess, dispatch]);

   const showModal = () => {
      if (userInfo.isSuperAdmin) {
         return null;
      } else {
         setIsEditRecom(true);
      }
   };
   const handleOk = () => {
      setIsEditRecom(false);
   };
   const handleCancel = () => {
      setIsEditRecom(false);
      window.location.reload();
   };
   const handleCancelIsError = () => {
      setModalIsError(false);
      window.location.reload();
   };
   const handleCancelIsConfirm = () => {
      setModalIsConfirm(false);
      window.location.reload();
   };

   const onFinish = (values) => {
      //   console.log('values',values);

      setDataUpdateRecom(values);
      setModalIsConfirm(true);
      // dispatch(editRecommendUser(values))
   };

   const handleUpdateRecom = () => {
      dispatch(editRecommendUser(DataUpdateRecom));
   };

   let allData2 = null;

   if (allData.length >= 1) {
      allData2 = Object.keys(allData[0]?.sumBonusByDate).map((date) => ({
         date,
         deposit: allData[0].sumDepositByDate[date] || 0,
         order: allData[0].sumOrderByDate[date] || 0,
         bonus: allData[0].sumBonusByDate[date] || 0,
      }));
   }

   const dataWithDateObjects = allData2?.map((item) => ({
      ...item,
      dateObj: new Date(
         `${item.date.split('/')[1]}/${item.date.split('/')[0]}/${
            item.date.split('/')[2]
         }`
      ),
   }));

   // Sort the data by the dateObj property
   const sortedData = dataWithDateObjects?.sort(
      (a, b) => a.dateObj - b.dateObj
   );

   // Remove the dateObj property from the sorted data
   const sortedDataWithoutDateObjects = sortedData?.map((item) => {
      const { dateObj, ...rest } = item;
      return rest;
   });

   const handleClick = (value) => {
      if (value && value.activePayload && value.activePayload.length > 0) {
         const { date, deposit, order, bonus } = value.activePayload[0].payload;

         const formattedMessage = `
          วันที่: ${date}
          เติมพีวี: ${commaNumber(deposit)}
          ขายพีวี: ${commaNumber(order)}
          โบนัส: ${commaNumber(bonus)}
        `;

         alert(formattedMessage);
      } else {
         alert('No data available');
      }
   };

   return (
      <DefaultLayout>
         {' '}
         {isLoading ? (
            <>
               {' '}
               <Loading />{' '}
            </>
         ) : (
            <>
               {' '}
               <div>
                  <div>
                     {userInfo.isSuperAdmin !== true && (
                        <>
                           <div className='d-flex flex-wrap gap-5'>
                              <Button
                                 onClick={showModal}
                                 className='mb-3 second-shadow2'
                                 style={{
                                    backgroundColor: '#fdc57b',
                                    color: 'black',
                                 }}
                              >
                                 เปลี่ยนรหัสแนะนำ
                              </Button>
                              <ExportUserRegister />

                              <Button
                                 onClick={()=> navigate('/admin/exportmatching')}
                                 className='mb-3 second-shadow2'
                                 style={{
                                    backgroundColor: '#7ba4fd',
                                    color: 'black',
                                 }}
                              >
                                 Export ค่าบริหารผู้ช่วย
                              </Button>
                           </div>
                        </>
                     )}
                     <TodaySale />
                     <br />
                     <Products />
                     <br />
                  </div>
                  <div
                     className='mb-5 mt-2'
                     style={{ backgroundColor: 'white', borderRadius: '20px' }}
                  >
                     <div>
                        <div
                           className='main-shadow'
                           style={{ width: '100%', height: 500 }}
                        >
                           <h5 className='p-4'>
                              <b>คอมมิชชั่น</b>
                           </h5>
                           <ResponsiveContainer height='80%'>
                              <AreaChart
                                 width={500}
                                 height={400}
                                 data={sortedDataWithoutDateObjects}
                                 margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                 }}
                              >
                                 <XAxis dataKey='date' />
                                 <YAxis />
                                 <Tooltip
                                    formatter={(value) => commaNumber(value)}
                                 />
                                 <Area
                                    type='monotone'
                                    dataKey='bonus'
                                    stroke='#f95959'
                                    fill='#f95959'
                                 />
                              </AreaChart>
                           </ResponsiveContainer>
                        </div>
                     </div>
                  </div>
                  <br />
                  <br />
                  <div className='mt-4'>
                     <Centers />
                  </div>
                  <div className='text-center  '>
                     <Row
                        gutter={[26, 30]}
                        style={{ fontWeigth: 'bold' }}
                     ></Row>
                  </div>
               </div>{' '}
               <Modal
                  title='เปลี่ยนรหัสผู้แนะนำ'
                  open={isEditRecom}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={false}
               >
                  <div>
                     <Form
                        name='เปลี่ยนรหัสผู้แนะนำ'
                        labelCol={{
                           span: 6,
                        }}
                        wrapperCol={{
                           span: 18,
                        }}
                        onFinish={onFinish}
                        autoComplete='off'
                     >
                        <Form.Item
                           name='userId'
                           label='รหัสสมาชิก'
                           style={{ color: 'black' }}
                           rules={[
                              { required: true, message: 'กรุณากรอกข้อมูล' },
                              { min: 7, message: 'รหัสสมาชิกต้องมี 7 หลัก' },
                           ]}
                        >
                           <Input
                              maxLength={7}
                              min={7}
                              style={{ color: 'black' }}
                           />
                        </Form.Item>
                        <Form.Item
                           name='recId'
                           label='รหัสผู้แนะนำใหม่'
                           style={{ color: 'black' }}
                           rules={[
                              { required: true, message: 'กรุณากรอกข้อมูล' },
                              { min: 7, message: 'รหัสสมาชิกต้องมี 7 หลัก' },
                           ]}
                        >
                           <Input
                              maxLength={7}
                              min={7}
                              style={{ color: 'black' }}
                           />
                        </Form.Item>
                        <Button
                           type='primary'
                           htmlType='submit'
                           block
                           size='large'
                           className='btn-secondary mt-3'
                        >
                           ยืนยัน
                        </Button>
                     </Form>
                  </div>
               </Modal>
               <Modal
                  title='ยืนยันการทำรายการ'
                  open={modalIsConfirm}
                  onCancel={handleCancelIsConfirm}
                  footer={false}
               >
                  <h5
                     style={{
                        fontSize: '30px',
                        textAlign: 'center',
                        color: 'red',
                     }}
                  >
                     <b> ยืนยันการเปลี่ยนรหัสแนะนำ</b>{' '}
                  </h5>
                  {DataUpdateRecom?.userId && (
                     <>
                        <p
                           className='mb-2'
                           style={{ fontSize: '16px', textAlign: 'center' }}
                        >
                           <b>
                              ยืนยันต้องการเปลี่ยนรหัสผู้แนะนำ ให้กับสมาชิก หรือ
                              ไม่
                           </b>
                        </p>
                        <p
                           className='mb-0'
                           style={{ fontSize: '16px', textAlign: 'center' }}
                        >
                           <b>
                              {' '}
                              รหัสสมาชิก :{' '}
                              <span className='text-danger'>
                                 {DataUpdateRecom?.userId}
                              </span>{' '}
                           </b>
                        </p>
                        <p
                           className='mb-0'
                           style={{ fontSize: '16px', textAlign: 'center' }}
                        >
                           <b>
                              {' '}
                              รหัสผู้แนะนำใหม่:{' '}
                              <span className='text-danger'>
                                 {DataUpdateRecom?.recId}
                              </span>{' '}
                           </b>
                        </p>
                     </>
                  )}
                  <br />
                  <br />
                  <div className='d-flex'>
                     <br />
                     <br />
                     <Button
                        style={{ backgroundColor: '#ffda30' }}
                        block
                        size='large'
                        onClick={() => setModalIsConfirm(false)}
                     >
                        <h5>
                           <b>ยกเลิก</b>
                        </h5>
                     </Button>{' '}
                     <Button
                        block
                        size='large'
                        type='primary'
                        htmlType='submit'
                        onClick={(event) => handleUpdateRecom(event)}
                     >
                        <h5 style={{ color: '#ffda30' }}>
                           <b>ยืนยัน</b>
                        </h5>
                     </Button>
                  </div>
               </Modal>
               <Modal
                  title='ทำรายการไม่สำเร็จ'
                  open={modalIsError}
                  onCancel={handleCancelIsError}
                  footer={false}
               >
                  <div>
                     <div className='text-center mb-3'>
                        <FaTimes size={128} color='red' />
                     </div>
                     <hr />
                     <div>
                        <p
                           className='mb-2'
                           style={{ fontSize: '16px', textAlign: 'center' }}
                        >
                           <b>รหัสสมาชิกดังต่อไปนี้ไม่ได้อยู่สายงานเดียวกัน</b>
                        </p>
                        <p
                           className='mb-0'
                           style={{ fontSize: '16px', textAlign: 'center' }}
                        >
                           <b>
                              {' '}
                              รหัสสมาชิก :{' '}
                              <span className='text-danger'>
                                 {DataUpdateRecom?.userId}
                              </span>{' '}
                           </b>
                        </p>
                        <p
                           className='mb-0'
                           style={{ fontSize: '16px', textAlign: 'center' }}
                        >
                           <b>
                              {' '}
                              รหัสผู้แนะนำใหม่:{' '}
                              <span className='text-danger'>
                                 {DataUpdateRecom?.recId}
                              </span>{' '}
                           </b>
                        </p>
                     </div>
                     <hr />
                     <h5 className='text-center'>
                        <b>{message}</b>
                     </h5>
                  </div>
               </Modal>
            </>
         )}{' '}
      </DefaultLayout>
   );
};

export default Home;

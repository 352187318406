import { Button, message, Statistic, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { getRecommendCommission } from '../features/commission/commissionSlice';
import { getSetting } from '../features/setting/settingSlice';
import DefaultLayout from '../components/DefualtLayout';

// import Excel from 'exceljs';
// import { saveAs } from 'file-saver';
import {
   getBonusPerId,
   reset,
} from '../features/adminFeatures/commission/commissionAdminSlice';

const ExportBonusPerDayDetail = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const location = useLocation();
   const { state } = location;
   const {date} = useParams()

   console.log('date: ', date);

   const [loadingPage, setLoadingPage] = useState(true);
   const { userInfo } = useSelector((state) => state.auth);



   const { bonus, isLoading } = useSelector((state) => state.commissionAdmin);
   const { reccommendCommsion, isLoading: isLoading2 } = useSelector((state) => state.commission);
   const { settings } = useSelector((state) => state.setting);

  // let spPrice = null;
   let keyPrice = null;
  // let spPercent = null;
   let transferFee = null;
   let tax = null;
   if (settings) {
      // spPrice = settings
      //    .filter((obj) => obj.name.includes('spPrice'))
      //    .map((obj) => obj.number);

      // spPercent = settings
      //    .filter((obj) => obj.name.includes('spPercent'))
      //    .map((obj) => obj.number);

      keyPrice = settings
         .filter((obj) => obj.name.includes('keyPrice'))
         .map((obj) => obj.number);

      transferFee = settings
         .filter((obj) => obj.name.includes('transferFee'))
         .map((obj) => obj.number);

      tax = settings
         .filter((obj) => obj.name.includes('tax'))
         .map((obj) => obj.number);

  //    spPercent.toString();
      transferFee.toString();
      tax.toString();
   }

 //  let spPercentInt = parseInt(spPercent);
   let transferFeeInt = parseInt(transferFee);
   let taxInt = parseInt(tax);

   let bonusToArray = Object.keys(bonus).map((key) => bonus[key]);

   // GET RECOMMEND COMMISSION
   const { result, result2, tUser, tUser2 } = reccommendCommsion;

   // GET RECOMMEND COMMISSION END

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }
      dispatch(getBonusPerId(date));
    //  dispatch(getRecommendCommission());
      dispatch(getSetting());

      return () => {
         dispatch(reset());
      };
   }, [dispatch, date]);

   console.log(bonusToArray);

   // const newBonus = bonusToArray.map((i) => {
   //    return {
   //       ...i,
   //       netSum: i.sum - ((i.sum * taxInt) / 100 + transferFeeInt),
   //       fee: (i.sum * taxInt) / 100 + transferFeeInt,
   //    };
   // });


   const newBonus = bonusToArray.map((i) => {
      return {
         ...i,
         sum: i.sum,
         netSum: i.netSum,
         fee: i.fee
      };
   });


   console.log(newBonus);

   const columns = [
      {
         title: 'ลำดับ',
         dataIndex: 'depth',
         key: 'depth',
         render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
         title: 'ID',
         dataIndex: 'userId',
      },
      {
         title: 'หมายเลขบัตรประชาชนหรือพาสปอร์ต',
         dataIndex: 'identification',
         key: 'identification',
        
      },
      {
         title: 'ชื่อสมาชิก',
         dataIndex: 'name',
         key: 'name',
      },
      {
         title: 'หมายเลขบัญชี',
         dataIndex: 'bankData',
         key: 'bankData',
         render: (record) => (
            <>
            <p>
            {record && record.bankAccount}
            </p>
            </>
         )
      },
      {
         title: 'ธนาคาร',
         dataIndex: 'bankData',
         key: 'bankData',
         render: (record) => (
            <>
            <p>
               {record && record.bank}
            </p>
            </>
         )
      },
      {
         title: 'โบนัส',
         dataIndex: 'sum',
         render: (record) => (
            <>
               <p className='text-primary'>{record ? record.toLocaleString() : record}</p>
            </>
         ),
      },
      {
         title: 'ค่าธรรมเนี่ยม',
         dataIndex: 'fee',
         render: (record) => (
            <>
               <p className='text-danger'>-{record ? record.toLocaleString() : record}</p>
            </>
         ),
      },
      {
         title: 'เงินที่โอน',
         dataIndex: 'netSum',
         key: 'netSum',
         render: (value, record) => (
            <>
               {/* <p>
                  {' '}
                  <Statistic suffix='฿' value={record} />{' '}
               </p> */}
               <p
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                     //navigate('/admin/exportBonusPerson',{state: { cardId: record.cardId, date: state.date },} )
                     navigate(`/admin/exportBonusPerson/${record.identification}/${date}`)
                  }>
                  {' '}
                  {value ? value.toLocaleString() : value}{' '}
               </p>
            </>
         ),
      },
   ];

   const headers = [
      { label: 'ชื่อสมาชิก', key: 'name' },
      { label: 'รหัสสมาชิก' , key: 'userId'},
      { label: 'ธนาคาร', key: 'bankData.bank' },
      { label: 'เลขบัญชี', key: 'bankData.bankAccount' },
      { label: 'รายได้สุทธิ', key: 'netSum' },
   ];

   const cl = [
      { header: 'ชื่อสมาชิก', key: 'name', width: 30 },
      { header: 'ธนาคาร', key: 'bankData.bank' },
      { header: 'เลขบัญชี', key: 'bankData.bankAccount' },
      { header: 'รายได้สุทธิ', key: 'netSum', width: 40 },
   ];

  


   return (
      <DefaultLayout>
         {loadingPage || isLoading || isLoading2 || newBonus.length < 1 ? (
            <Loading />
         ) : (
            <>
               <div className='d-flex justify-content-around'>
                  <Button type='danger' onClick={() => navigate(-1)}>
                     {' '}
                     กลับไป{' '}
                  </Button>
     

                  <h5>
                     {' '}
                     <span>สรุปยอดโบนัส งวดที่ </span>{' '}
                     {/* {moment(state.date).format('D/M/YYYY')}{' '} */}
                     {date}
                  </h5>
                  <Button>
                     <CSVLink
                        filename={`สรุปยอดรายงารสรุปการจ่ายของโบนัสรายวัน/${date}.csv`}
                        onClick={() => {
                           message.success('ดาวโหลดไฟล์');
                        }}
                        data={newBonus}
                        headers={headers}>
                        พิมย์รายงาน
                     </CSVLink>
                  </Button>

                  {/* <Button onClick={saveExcel}> save excel </Button> */}
               </div>
               <br />
               <Table
                  summary={(newBonus) => {
                     let totalAmount = 0;
                     newBonus.forEach(({ netSum }) => {
                        totalAmount += netSum;
                     });

                     return (
                        <>
                           <Table.Summary.Row>
                              <Table.Summary.Cell>
                                 {' '}
                                 <b> รวม</b>{' '}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell></Table.Summary.Cell>
                              <Table.Summary.Cell type='danger'>
                                 <p>
                                    {' '}
                                    <Statistic suffix='฿' value={totalAmount} />
                                 </p>
                              </Table.Summary.Cell>
                           </Table.Summary.Row>
                        </>
                     );
                  }}
                  columns={columns}
                  dataSource={newBonus}
               />
            </>
         )}
      </DefaultLayout>
   );
};

export default ExportBonusPerDayDetail;

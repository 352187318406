import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCenterDeposits, reset } from '../features/deposit/depositSlice';
import { Table, Row, Col } from 'antd';
import commaNumber from 'comma-number';
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Rectangle,
} from 'recharts';
import styled from 'styled-components';

export const Centers = () => {
  const dispatch = useDispatch();

  const { centers, isLoading, isError, message, isSuccess } = useSelector(
    (state) => state.deposit
  );

  console.log('centers: ', centers);

  const totalSumPV = centers.reduce((sum, center) => sum + center.pv, 0);

  const [currentMonth, setCurrentMonth] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const monthOptions = [
    { value: 'มกราคม', label: 'มกราคม', num: 1 },
    { value: 'กุมภาพันธ์', label: 'กุมภาพันธ์', num: 2 },
    { value: 'มีนาคม', label: 'มีนาคม', num: 3 },
    { value: 'เมษายน', label: 'เมษายน', num: 4 },
    { value: 'พฤษภาคม', label: 'พฤษภาคม', num: 5 },
    { value: 'มิถุนายน', label: 'มิถุนายน', num: 6 },
    { value: 'กรกฎาคม', label: 'กรกฎาคม', num: 7 },
    { value: 'สิงหาคม', label: 'สิงหาคม', num: 8 },
    { value: 'กันยายน', label: 'กันยายน', num: 9 },
    { value: 'ตุลาคม', label: 'ตุลาคม', num: 10 },
    { value: 'พฤศจิกายน', label: 'พฤศจิกายน', num: 11 },
    { value: 'ธันวาคม', label: 'ธันวาคม', num: 12 },
  ];

  const handleMonthChange = (selectedOption) => {
    setCurrentMonth(selectedOption.value);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const now = new Date();
    const month = now.toLocaleString('th-TH', { month: 'long' });
    setCurrentMonth(month);
  }, []);

  useEffect(() => {
    if (currentMonth !== '') {
      dispatch(getCenterDeposits(currentMonth));
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch, currentMonth]);

  const columns = [
    {
      title: 'ลำดับ',
      dataIndex: 'depth',
      key: 'depth',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'เซนเตอร์',
      dataIndex: 'name',
    },
    {
      title: 'pv คงเหลือ',
      dataIndex: 'pv',
      render: (value) => <span>{commaNumber(value)}</span>,
    },
    {
      title: 'ยอดเติม PV',
      dataIndex: 'totalAmount',
      render: (value) => <span>{commaNumber(value)}</span>,
    },
  ];

  const handleClick = (value) => {
    if (value && value.activePayload && value.activePayload.length > 0) {
      const { name, totalAmount } = value.activePayload[0].payload;

      const formattedMessage = `

        เซ็นเตอร์: ${commaNumber(name)}
        ยอดเติมพีวี: ${commaNumber(totalAmount)}
      `;

      alert(formattedMessage);
    } else {
      alert('No data available');
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            border: '0.5px solid rgb(204, 204, 204)',
          }}
        >
          <p>{label}</p>
          <p>ยอด : {commaNumber(payload[0].value)}</p>
        </div>
      );
    }
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col sm={24} xs={24} md={24} lg={12} xl={10}>
          <div style={{ width: '100%' }} className='dashboard-box main-shadow '>
            <h5 className='p-4 text-center'>
              <b
                className='cursor-pointer'
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                ยอดเติมพีวี เดือน : {currentMonth}{' '}
              </b>
            </h5>

            <h5 className='text-center'>
              <b className='p-4  '>
                ยอดรวมพีวีคงเหลือทั้งหมด : {commaNumber(totalSumPV)} PV
              </b>
            </h5>

            {isDropdownOpen && (
              <DropdownContainer isOpen={isDropdownOpen}>
                <SelectDiv>
                  <ul>
                    {monthOptions.map((e) => (
                      <li key={e.value} onClick={() => handleMonthChange(e)}>
                        {e.value}
                      </li>
                    ))}
                  </ul>
                </SelectDiv>
              </DropdownContainer>
            )}

            <Table header columns={columns} dataSource={centers} />
          </div>
        </Col>
        <Col sm={24} xs={24} md={24} lg={12} xl={14}>
          {' '}
          <div
            style={{ width: '100%', height: '100%', overflowX: 'scroll' }}
            className='dashboard-box main-shadow'
          >
            <h5 className='text-center mt-4'>
              <b>กราฟยอดเติมพีวีเดือน : {currentMonth}</b>
            </h5>

            <div
              style={{
                width: '2500px',
                height: '80%',
                marginTop: '10%',
              }}
            >
              <ResponsiveContainer height='100%' width='100%'>
                <BarChart
                  onClick={(value) => {
                    handleClick(value);
                  }}
                  width={1000}
                  height={600}
                  data={centers}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  {/* <CartesianGrid strokeDasharray='3 3' /> */}
                  <XAxis
                    dataKey='name'
                    interval={0}
                    angle={-45}
                    textAnchor='end'
                    height={100}
                  />
                  <YAxis />
                  <Tooltip
                    formatter={(value) => commaNumber(value)}
                    content={<CustomTooltip />}
                  />
                  {/* <Legend /> */}
                  <Bar
                    dataKey='totalAmount'
                    fill='#2772db'
                    // radius={[10, 10, 0, 0]}
                    activeBar={<Rectangle fill='#2772db' stroke='black' />}
                    // label={{
                    //    position: 'top',
                    //    formatter: (value) => ` ${commaNumber(value)}`,
                    // }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  .dashboard-box {
    box-shadow: var(--main-shadow);
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50px; /* Adjust based on your needs */
  z-index: 1000;
  max-height: ${(props) =>
    props.isOpen ? '500px' : '0'}; /* Adjust 200px based on content height */
  overflow: hidden;

  transition-timing-function: ease-in;
  transition: 0.2s;
`;

const SelectDiv = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  transition: 0.25s;
  transition-timing-function: ease-out;

  transform: translateY(0);
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 8px 12px;
      cursor: pointer;
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
`;

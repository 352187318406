import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from './authService';

// Get user from localStorage
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const initialState = {
   userInfo: userInfo ? userInfo : null,
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: '',
};

// Register user
export const register = createAsyncThunk(
   'auth/register',
   async (userInfo, thunkAPI) => {
      try {
         return await authService.register(userInfo);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// Login user
export const login = createAsyncThunk(
   'auth/login',
   async (userInfo, thunkAPI) => {
      try {
         return await authService.login(userInfo);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const logout = createAsyncThunk('auth/logout', async () => {
   await authService.logout();
});

export const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      reset: (state) => {
         state.isLoading = false;
         state.isSuccess = false;
         state.isError = false;
         state.message = '';
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(register.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(register.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.userInfo = action.payload;
         })
         .addCase(register.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.userInfo = null;
         })
         .addCase(login.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(login.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.userInfo = action.payload;
         })
         .addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.userInfo = null;
         })
         .addCase(logout.fulfilled, (state) => {
            state.userInfo = null;
         });
   },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;

import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefualtLayout';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading';
import { Button, Statistic, Table } from 'antd';

import moment from 'moment';
import { getSetting } from '../features/setting/settingSlice';
import { getAllMatching, getMatchingData } from '../features/commission/commissionSlice';

const ExportMatchingPerDay = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const location = useLocation();
   const { date } = useParams();

   const { settings } = useSelector((state) => state.setting);

   let transferFee = null;
   let tax = null;
   if (settings) {
      transferFee = settings
         .filter((obj) => obj.name.includes('transferFee'))
         .map((obj) => obj.number);

      tax = settings
         .filter((obj) => obj.name.includes('tax'))
         .map((obj) => obj.number);

      transferFee.toString();
      tax.toString();
   }
   let transferFeeInt = parseInt(transferFee);
   let taxInt = parseInt(tax);
   const monthIndex = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
   ].indexOf(date.toLowerCase());
   const [loadingPage, setLoadingPage] = useState(true);

   const { mathcing, isLoading } = useSelector(
      (state) => state.commissionAdmin
   );

   function isValidDate(dateString) {
      const date = moment(dateString, 'DD/MM/YYYY');
      return date.isValid();
   }

   const users = mathcing.map((e) => ({
      userId: e.userId,
      name: e.name,
   }));
   const usersId2 = [];
   //  const usersId2 = [...new Set(users)];
   for (let i = 0; i < users.length; i++) {
      let isDuplicate = false;
      for (let j = 0; j < usersId2.length; j++) {
         if (users[i].userId === usersId2[j].userId) {
            isDuplicate = true;
            break;
         }
      }
      if (!isDuplicate) {
         usersId2.push(users[i]);
      }
   }

   console.log(usersId2);

   const newData = mathcing.map((obj) => ({
      ...obj,
      date: isValidDate(obj.date)
         ? moment(obj.date, 'DD/MM/YYYY').toDate()
         : obj.date,
   }));

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      dispatch(getMatchingData());
      dispatch(getSetting());
   }, []);

   const reducedData = mathcing.reduce((acc, item) => {
      const month = moment(item.date, 'DD/MM/YYYY').format('YYYY-MM');
      if (!acc[month]) {
         acc[month] = [];
      }
      acc[month].push(item);
      return acc;
   }, {});

   // console.log(reducedData);

   const marchData = newData.filter((obj) => {
      const date = new Date(obj.date);
      return date.getMonth() === monthIndex; // 2 is the index for March (January is 0)
   });

   const columns = [
      {
         title: 'date',
         dataIndex: 'date',
         render: (value) => <>{moment(value).format('DD/MM/YYYY')}</>,
         sorter: (a,b) => moment(a.date).unix() - moment(b.date).unix()
      },
      {
         title: 'ลำดับ',
         dataIndex: 'depth',
         key: 'depth',
          render: ( record) => <span>{record + 1}</span>,
      },
      {
         title: 'ธนาคาร',
         dataIndex: 'bankData',
         key: 'bankData',
         render: (record) => (
            <>
               <p>{record && record.bank}</p>
            </>
         ),
      },
      {
         title: 'โบนัส',
         dataIndex: 'netAmount',
         render: (record) => (
            <>
               <p className='text-primary'>
                  {record ? record.toLocaleString() : record}
               </p>
            </>
         ),
      },
      {
         title: 'ค่าธรรมเนี่ยม',
         dataIndex: 'netAmount',
         render: (record) => (
            <>
               <p className='text-danger'>
                  {/* {parseInt(-((record * 5) / 100 + 30))} */}
               -   {((record * 5) / 100 + 30)}
               </p>
            </>
         ),
      },
      {
         title: 'เงินที่โอน',
         dataIndex: 'netAmount',
         key: 'netAmount',
         render: (value, record) => (
            <>
               {/* <p>
                {' '}
                <Statistic suffix='฿' value={record} />{' '}
             </p> */}
            <Link to={`/admin/exportBonusMatchingSummary/${record._id}/${moment(record.date).format('DD-MM-YYYY')}/${record.depth}`}>
            <p
                  style={{ cursor: 'pointer' }} 
                  // onClick={() =>
                  //    navigate('/admin/exportBonusPerson', {
                  //       state: { cardId: record.cardId, date: state.date },
                  //    })
                  // }
               >
                  {' '}
                  {/* {parseInt(value - ((value * 5) / 100 + 30))} */}
                  { value - ((value *5) /100 +30)}
               </p>
            </Link>
            </>
         ),
      },
   ];
   //  const check = false;
   const [check, setCheck] = useState(false);
   const [user, setUser] = useState(null);
   const [username, setUsername] = useState(null)
   const columnsUsers = [
      {
         dataIndex: 'userId',
      },
      {
         dataIndex: 'name',
      },
      {
         dataIndex: 'Details',
         render: (_, row) => (
            <>
               {/* <Button
                  onClick={() => {
                     setCheck(true);
                     setUser(row.userId);
                     setUsername(row.name)
                  }}
               >
                  {' '}
                  Details{' '}
               </Button> */}
                  <p
                  style={{cursor : 'pointer'}}
                  onClick={() => {
                     setCheck(true);
                     setUser(row.userId);
                     setUsername(row.name)
                  }}
               >
                  {' '}
                  Details{' '}
               </p>
            </>
         ),
      },
   ];

   const newMonthData = marchData.filter((e) => e.userId === user);
   let newMonthData2 = newMonthData.map((e) => {
      return {
         ...e,
         netAmount: (e.amount * 5) / 100,
         // amountToPay:(( e.amount *0.5)*0.5)
      };
   });
   console.log(newMonthData2);

  

   return (
      <DefaultLayout>
         {loadingPage ? (
            <Loading />
         ) : (
            <>
               <div>
                  {check ? (
                     <>
                      <div className='d-flex justify-content-between'>
                      <h4> {date} </h4>
                        <h4> {user} </h4>
                        <h4> {username} </h4>
                        <Button
                           onClick={() => {
                              setCheck(false);
                              setUser(null);
                              setUsername(null)
                           }}
                        >
                           {' '}
                           Go Back{' '}
                        </Button>
                      </div>
                        <Table
                        summary={(newMonthData2) => {
                           let totalAmount = 0;
                           newMonthData2.forEach(({ netAmount}) => {
                              totalAmount +=( netAmount - ((netAmount *  5) /100 + 30)) 
                           } )                         
                           return (
                              <>
                                 <Table.Summary.Row>
                                    <Table.Summary.Cell>
                                       {' '}
                                       <b> รวม</b>{' '}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell type='danger'>
                                       <p>
                                          {' '}
                                          <Statistic suffix='฿' precision={0} value={totalAmount} />
                                       </p>
                                    </Table.Summary.Cell>
                                 </Table.Summary.Row>
                              </>
                           );  
                        }}
                         columns={columns} dataSource={newMonthData2} />
                     </>
                  ) : (
                     <>
                        <Table columns={columnsUsers} dataSource={usersId2} />
                     </>
                  )}
               </div>
            </>
         )}
      </DefaultLayout>
   );
};

export default ExportMatchingPerDay;

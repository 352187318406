import axios from 'axios';

// const API_URL = 'http://localhost:8000/api/usersbe/commission/';
// const API_URL2 = 'http://localhost:8000/api/usersbe/';

let API_URL = '';
let API_URL2 = '';
if (process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:8000/api/usersbe/commission/';
   API_URL2 = 'http://localhost:8000/api/usersbe/';
} else {
   API_URL = '//api.admin-siamprai.com/api/usersbe/commission/';
   API_URL2 = '//api.admin-siamprai.com/api/usersbe/';
}

const getAllBonus = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getAllBonus', config);
   return response.data;
};

const getBonusPerId = async (date, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getBonusPerId', {
      params: {
         date,
      },
      headers: {
         Authorization: `Bearer ${token}`,
      },
   });
   return response.data;
};

const getCommissionPerson = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
      params: data,
   };

   const res = await axios.get(API_URL2 + 'commision/getUserBonus', config);
   return res.data;
};



const getRecommendCommission = async (token, data) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(
      API_URL + `recpoint/${data.id}/${data.date}/${data.depth}`,
      config
   );
   return response.data;
};

const getCurrentPromotionByCardId = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(
      API_URL + `getCurrentPromotionByCardId/${data.date}/${data.cardId}`,
      config
   );
   return response.data;
};

const getDataForIncomeGraph = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + `getDataForIncomeGraph`, config);
   return response.data;
};

const SummaryCommissionUsersPerWeek = async (month,token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + `SummaryCommissionUsersPerWeek/${month}`, config);
   return response.data;
};


const approveBonus = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL + `approveBonus`, data, config);
   return response.data;
};

const updateStatusToIsPaid = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.patch(
      API_URL + `updateStatusToIsPaid`,
      data,
      config
   );
   return response.data;
};

const userAdminService = {
   getAllBonus,
   getBonusPerId,
   getCommissionPerson,
   getRecommendCommission,
   getCurrentPromotionByCardId,
   approveBonus,
   updateStatusToIsPaid,
   getDataForIncomeGraph,
   SummaryCommissionUsersPerWeek
};

export default userAdminService;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import commissionAdminService from './commissionAdminService';
import Cookies from 'js-cookie';

const initialState = {
   commissions: [],
   allData: [],
   users: [],
   bonus: [],

   isError: false,
   isSuccess: false,
   isLoading: false,
};


export const getDataForIncomeGraph = createAsyncThunk(
   'commission/getDataForIncomeGraph',
   async (_,thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionAdminService.getDataForIncomeGraph(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
)
export const SummaryCommissionUsersPerWeek = createAsyncThunk(
   'commission/SummaryCommissionUsersPerWeek',
   async (month,thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionAdminService.SummaryCommissionUsersPerWeek(month,token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
)

export const getAllBonus = createAsyncThunk(
   'commission/getAllBonus',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionAdminService.getAllBonus(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getBonusPerId = createAsyncThunk(
   'commission/getBonusPerId',
   async (date, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionAdminService.getBonusPerId(date, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getCommissionPerson = createAsyncThunk(
   'commission/getCommissionPerson',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionAdminService.getCommissionPerson(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getRecommendCommission = createAsyncThunk(
   'commission/getRecommendCommission',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionAdminService.getRecommendCommission(token,data);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getCurrentPromotionByCardId = createAsyncThunk(
   'commission/getCurrentPromotionByCardId',
   async (  data , thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionAdminService.getCurrentPromotionByCardId(
             data,
            token
         );
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const approveBonus = createAsyncThunk(
   'commission/approveBonus',
   async (  data , thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionAdminService.approveBonus(
             data,
            token
         );
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);
export const updateStatusToIsPaid = createAsyncThunk(
   'commission/updateStatusToIsPaid',
   async (  data , thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await commissionAdminService.updateStatusToIsPaid(
             data,
            token
         );
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

const commissionAdminSlice = createSlice({
   name: 'commission',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAllBonus.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAllBonus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.bonus = action.payload;
         })
         .addCase(getAllBonus.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.bonus = null;
         })
         .addCase(getDataForIncomeGraph.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getDataForIncomeGraph.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.allData.push(action.payload);
         })
         .addCase(getDataForIncomeGraph.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.bonus = null;
         })
         .addCase(SummaryCommissionUsersPerWeek.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(SummaryCommissionUsersPerWeek.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.commissions = action.payload
         })
         .addCase(SummaryCommissionUsersPerWeek.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.bonus = null;
         })
   
         .addCase(getBonusPerId.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getBonusPerId.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.bonus = action.payload;
         })
         .addCase(getBonusPerId.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.bonus = null;
         })
         .addCase(getCommissionPerson.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCommissionPerson.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.commissions = action.payload;
         })
         .addCase(getCommissionPerson.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getCurrentPromotionByCardId.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCurrentPromotionByCardId.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.promotion = action.payload;
         })
         .addCase(getCurrentPromotionByCardId.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.promotion = null;
         })
         .addCase(getRecommendCommission.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getRecommendCommission.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.reccommendCommsion = action.payload;
         })
         .addCase(getRecommendCommission.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(approveBonus.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(approveBonus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.commissions = action.payload;
         })
         .addCase(approveBonus.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(updateStatusToIsPaid.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateStatusToIsPaid.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;

         })
         .addCase(updateStatusToIsPaid.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = commissionAdminSlice.actions;
export default commissionAdminSlice.reducer;

import axios from 'axios';

const API_URL = '//simaprai-backend.th1.proen.cloud/api/center/';

const API = axios.create({ baseURL: '/api/center' });

API.interceptors.request.use((req) => {
   if (localStorage.getItem('userInfo')) {
      req.headers.Authorization = `Bearer ${
         JSON.parse(localStorage.getItem('userInfo')).token
      }`;
   }
   return req;
});

// Create  center
const createCenter = async (centerData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.post(API_URL, centerData, config);

   return response.data;
};

// Gets Centers
const getCenters = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL, config);
   return response.data;
};

// GET MY CENTER
const getCenter = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(API_URL + `/${id}`, config);
   return res.data;
};

// GET CENTER BY ID
const getCenterId = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const res = await axios.get(API_URL + `/${id}`, config);
   return res.data;
};


// UPDATE CENTER
// const updateCenter = async (centerId, centerData, token) => {
//    const config = {
//       headers: {
//          Authorization: `Bearer ${token}`,
//       },
//    };

//    const res = await axios.put(API_URL + `/${centerId}`, centerData, config);
//    return res.data;
// };

const updateCenter = (centerId, centerData) =>
   API.put(`/${centerId}`, centerData);

// Delete  center
const deleteCenter = async (centerId, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.delete(API_URL + centerId, config);

   return response.data;
};

const centerService = {
   getCenters,
   createCenter,
   deleteCenter,
   getCenter,
   updateCenter,
};

export default centerService;

import React, { useEffect, useRef, useState } from 'react';
import DefaultLayout from '../components/DefualtLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
   cancelDeposit,
   getDepositsById,
   updateStatus,
} from '../features/deposit/depositSlice';
import {
   Button,
   Form,
   Image,
   List,
   Modal,
   Table,
   Tag,
   Input,
   Space,
   Statistic,
   Radio,
} from 'antd';
import { FaCheck, FaTimesCircle } from 'react-icons/fa';
import moment from 'moment';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import { BiSearch } from 'react-icons/bi';
import { AdminUpdateEwallet, reset } from '../features/ewallet/ewalletSlice';

const DepositDetailPage = () => {
   const { id } = useParams();
   const [form] = Form.useForm();
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [noteCancel, setNoteCancel] = useState('');
   const [searchText, setSearchText] = useState('');
   const [searchedColumn, setSearchedColumn] = useState('');
   const searchInput = useRef(null);

   const [confirmNumber, setConfirmNumber] = useState('');

   const [dataSelected, setDataSelected] = useState({});
   const [data, setData] = useState(null);
   const [modal, setModal] = useState(false);
   const [cancelModal, setCancelModal] = useState(false);

   const { deposits, isLoading, isError, message, isSuccess } = useSelector(
      (state) => state.deposit
   );

   const [loading, setLoading] = useState(false);
   const [loading2, setLoading2] = useState(false);

   let deposits2 = null;
   if (deposits.deposit) {
      deposits2 = deposits.deposit;
   }

   useEffect(() => {
      dispatch(getDepositsById(id));
   }, [dispatch]);

   const CheckStatus = (data) => {
      setData(data);
      setModal(true);
   };

   let dataArray = null;

   if (data) {
      dataArray = Object.entries(data).map(([key, value]) => ({
         key,
         value,
      }));
   }

   const updateStatusDeposit = (data) => {
      console.log('data');
      console.log(data);
      const confirm = window.confirm('Confirm ');
      setLoading(true);
      const data2 = {
         id: id,
         deposit_id: data._id,
      };

      setTimeout(() => {
         setLoading(false);
         //  navigate(`/admin/transfer/${id}/${data.amount}`)

         if (confirm === true) {
            dispatch(updateStatus(data2));
         } else {
            window.location.reload();
         }
      }, 3000);
   };

   const updateStatusDepositAndTransfer = (data) => {

console.log('data:', data);

      const data2 = {
         id: id,
         deposit_id: data._id,
      };

      let newData = { ...data };

      newData.userId = id;
      newData.confirmNumber = confirmNumber;

      console.log('data2: ', data2);

      // const confirm = window.confirm('Confirm ');
      // if (confirm === true) {
      //    dispatch(updateStatus(data2));
      // } else {
      //    window.location.reload();
      // }
      setLoading2(true);

      setTimeout(() => {
         setLoading2(false);
     //    dispatch(AdminUpdateEwallet(newData));
     //    dispatch(updateStatus(data2));
    //     dispatch(reset());
         //    navigate(`/admin/transfer/${id}/${data._id}/${data.refNumber}/${data.amount}/${confirmNumber}`);
      }, 3000);

      //  navigate(`/admin/transfer/${id}/${data.amount}`)
   };

   const handlerCancelDeposit = () => {
      let newData = { ...dataSelected };

      newData.note = noteCancel;

      console.log('nData:', dataSelected._id);

      const depositId = dataSelected._id;

      dispatch(cancelDeposit({ id,depositId, noteCancel }));
   };

   // search
   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };
   const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
   };
   const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
         setSelectedKeys,
         selectedKeys,
         confirm,
         clearFilters,
         close,
      }) => (
         <div
            style={{
               padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
         >
            <Input
               ref={searchInput}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
               }
               onPressEnter={() =>
                  handleSearch(selectedKeys, confirm, dataIndex)
               }
               style={{
                  marginBottom: 8,
                  display: 'block',
               }}
            />
            <Space>
               <Button
                  type='primary'
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<BiSearch />}
                  size='small'
                  style={{
                     width: 90,
                  }}
               >
                  Search
               </Button>
               <Button
                  onClick={() => clearFilters && handleReset(clearFilters)}
                  size='small'
                  style={{
                     width: 90,
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: (filtered) => (
         <BiSearch
            style={{
               color: filtered ? '#1890ff' : undefined,
            }}
         />
      ),
      onFilter: (value, record) =>
         record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
         if (visible) {
            setTimeout(function () {
               if (searchInput.current) {
                  return searchInput.current.select();
               }
            }, 100);
         }
      },
      render: (text) =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{
                  backgroundColor: '#ffc069',
                  padding: 0,
               }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            //    <>
            //   <b style={{ backgroundColor: '#ffc069'}}> {text} </b>
            //    </>
            text
         ),
   });

   // search END

   const column = [
      {
         title: 'เลขที่อ้างอิง',
         dataIndex: 'refNumber',
         ...getColumnSearchProps('refNumber'),
         render: (value, record) => (
            <div className='d-flex'>
               <Tag
                  style={{ cursor: 'pointer' }}
                  onClick={() => CheckStatus(record)}
                  color='blue'
               >
                  {value}
               </Tag>
               {/* <Tag style={{cursor: 'pointer'}} color='red'>Delete</Tag> */}
            </div>
         ),
      },
      {
         title: 'วันที่ทำรายการ',
         dataIndex: 'date',
         render: (value) => moment(value).format('MMMM Do YYYY, h:mm:ss a'),
      },
      {
         title: 'ยอดเงิน',
         dataIndex: 'amount',
         render: (value) => <Statistic value={value} />
      },

      {
         title: 'วันที่อนุมัติ',
         dataIndex: 'dateApprove',
         render: (value) => (
            <>
               {value === null ? (
                  <>
                     <h5>ยังไม่อนุมัติ</h5>
                  </>
               ) : (
                  <>{moment(value).format('MMMM Do YYYY, h:mm:ss a')}</>
               )}
            </>
         ),
      },

      {
         title: 'สลิป',
         dataIndex: 'imgSlip',
         render: (value) => <Image src={value.url} height={150} width={150} />,
      },
      {
         title: 'ยกเลิกรายการ',
         render: (record) => (
            <div className='d-flex'>
               <Tag
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                     setDataSelected(record);
                     setCancelModal(true);
                  }}
                  color='red'
               >
                  ยกเลิก
               </Tag>
               {/* <Tag style={{cursor: 'pointer'}} color='red'>Delete</Tag> */}
            </div>
         ),
      },
      {
         title: 'สถานะ',
         dataIndex: 'status',
         defaultSortOrder: 'descend',
         sorter: (a, b) => a.status - b.status,
         render: (value, record) => {
            if (value === 'สำเร็จ') {
               return (
                  //   <FaCheck style={{color: 'green'}} />
                  <Tag color='green'>{value}</Tag>
               );
            } else if (value === 'รออนุมัติ') {
               return <Tag color='volcano'>{value}</Tag>;
            }
         },
      },
   ];

   const onChange = (e) => {
      console.log('radio checked', e.target.value);
      setNoteCancel(e.target.value);
   };

   return (
      <DefaultLayout>
         <div>
            <h3 className='mt-3 mb-5 text-center'> Deposit List </h3>
            <Table dataSource={deposits2} columns={column} />
         </div>
         <Modal
            title='ยกเลิกรายการ'
            visible={cancelModal}
            footer={false}
            onCancel={() => setCancelModal(false)}
         >
            <Radio.Group onChange={onChange} value={noteCancel}>
               <Space direction='vertical'>
                  <Radio value='ยอดเงินไม่ตรงกับที่แจ้ง'>
                     ยอดเงินไม่ตรงกับที่แจ้ง
                  </Radio>
                  <Radio value='ใช้ Slip ซ้ำ'>ใช้ Slip ซ้ำ</Radio>
                  <Radio value='ไม่ใช่บัญชีบริษัท'>ไม่ใช่บัญชีบริษัท</Radio>
                  <Radio value='ภาพไม่ชัด'>ภาพไม่ชัด</Radio>
                  <Radio value='ไม่ใช่ภาพ Slip'>ไม่ใช่ภาพ Slip</Radio>
                  <Radio value={6}>
                     อื่นๆ
                     {noteCancel === 6 ? (
                        <Input
                           onChange={(e) => setNoteCancel(e.target.value)}
                           style={{
                              width: 100,
                              marginLeft: 10,
                           }}
                        />
                     ) : null}
                  </Radio>
               </Space>
            </Radio.Group>
            <br />
            <Button
               onClick={handlerCancelDeposit}
               block
               className='btn btn-danger mt-4'
            >
               ยกเลิก
            </Button>
         </Modal>
         <Modal
            title='ยืนยันอนุมัติรายการ'
            visible={modal}
            footer={false}
            onCancel={() => setModal(false)}
         >
            {data != null && (
               <div className='d-flex flex-column'>
                  {/* <p>amount: {data.amount}</p>
                 <p>bank: {data.bank}</p>
                 <p>date: {data.date}</p> */}
                  <Form
                     form={form}
                     name='basic'
                     labelCol={{
                        span: 6,
                     }}
                     wrapperCol={{
                        span: 18,
                     }}
                  >
                     <Form.Item label='หมายเลขอ้างอิง'>
                        <TextStyle>{data.refNumber}</TextStyle>
                     </Form.Item>
                     <Form.Item label='วันที่ทำรายการ'>
                        <TextStyle>
                           {moment(data.date).format('MMMM Do YYYY, h:mm:ss a')}
                        </TextStyle>
                     </Form.Item>
                     <Form.Item label='ยอดเงิน'>
                        <TextStyle>
                           {' '}
                           <Statistic value={data.amount} />{' '}
                        </TextStyle>
                     </Form.Item>
                     <Form.Item label='PV'>
                        <TextStyle>
                           <Statistic value={data.totalPv} />
                        </TextStyle>
                     </Form.Item>
                     <Form.Item label='หมายเลขยืนยัน'>
                        <Input
                           onChange={(e) => setConfirmNumber(e.target.value)}
                        />
                     </Form.Item>
                  </Form>
                  <div>
                     {/* <ul>
                              {data.totalProduct.map((product) => (
                                 <li
                                    style={{ listStyle: 'none' }}
                                    key={product.name}
                                 >
                                    {product.name}: {product.amount}
                                 </li>
                              ))}
                           </ul> */}
                     <List
                        header={<strong>รายการสินค้าที่สั่งซื้อ</strong>}
                        bordered
                        dataSource={data.totalProduct}
                        renderItem={(item) => (
                           <List.Item>
                              <>
                                 <span>{item.name}</span> :{' '}
                                 <span>{item.amount} ชิ้น</span>
                              </>{' '}
                           </List.Item>
                        )}
                     />
                  </div>

                  <div
                     className='text-center'
                     style={{ marginBottom: '20px', marginTop: '20px' }}
                  >
                     <Image src={data.imgSlip.url} width={400} height={500} />
                  </div>

                  <div className='d-flex justify-content-center'>
                     {/* <Button
                       loading={loading}
                       type='primary'
                       onClick={() => updateStatusDeposit(data)}
                    >
                       {' '}
                       Approve Silp
                    </Button> */}

                     <Button
                        block
                        type='primary'
                        loading={loading2}
                        className='btn-success'
                        onClick={() => updateStatusDepositAndTransfer(data)}
                     >
                        {' '}
                        อนุมัติ
                     </Button>
                  </div>
               </div>
            )}
         </Modal>
      </DefaultLayout>
   );
};

const TextStyle = styled.p`
   margin: 0 !important;
   border-bottom: 1px dashed;
`;

export default DepositDetailPage;

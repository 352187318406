import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefualtLayout';
import { Button, Form, Input, Modal, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import {
   createAdmin,
   editAdmin,
   getAdmins,
   reset,
} from '../features/admin/userAdminSlice';
import { FiEdit } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import {toast} from 'react-toastify'

const AdminsPage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [form] = Form.useForm();
   const [isModal, setIsModal] = useState(false);
   const [editModal, setEditModal] = useState(false);
   const [deleteModal, setDeleteModal] = useState(false);

   const [adminId, setAdminId] = useState({});

   console.log('adming Id: ', adminId);

   const { userInfo } = useSelector((state) => state.auth);

   const {
      adminsUser,
      isLoading,
      isSuccess,
      deleteIsSuccess,
      isUpdateCenterSuccess,
      isUpdateCenterError,
      isAdminUpdatesuccess,
      isError,
      message,
   } = useSelector((state) => state.admin);

   const [isAdd, setIsAdd] = useState(false);

   useEffect(() => {
      if (userInfo.userId !== '1288872') {
         navigate('/admin/dashboard');
      }

      dispatch(getAdmins());

      return () => {
         dispatch(reset());
      };
   }, [dispatch]);

   useEffect(() => {
      form.setFieldValue({
         name: adminId.username,
         password: adminId.pass,
      });

      if(isAdminUpdatesuccess){
        toast.success('ทำรายการสำเร็จ')
      }
   }, [form, adminId,isAdminUpdatesuccess]);

   const onFinish = (values) => {
      dispatch(createAdmin(values));
   };

   const onFinishEditAdmin = (values) => {

    let data = {}
    data.userId = adminId.userId
    data.username = values.username
    data.password = values.password

    dispatch(editAdmin(data))


      console.log('vau:', values);
   };

   const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   const columns = [
      {
         title: 'รหัส',
         dataIndex: 'UserAdmin',
         render: (value) => value.userId,
      },
      {
         title: 'ชื่อที่แสดง',
         dataIndex: 'UserAdmin',
         render: (value) => value.username,
      },
      {
         dataIndex: 'UserAdmin',
         render: (record) => (
            <>
               <div className='d-flex gap-3'>
                  <FiEdit
                     color='orange'
                     onClick={() => {
                        setAdminId(record);
                        setEditModal(true);
                     }}
                     className='cursor-pointer'
                     size={24}
                  />
                  <MdDelete color='red' className='cursor-pointer' size={24} />
               </div>
            </>
         ),
      },
   ];

   return (
      <DefaultLayout>
         <Button
            onClick={() => {
               setIsAdd((prevState) => !prevState);
            }}
         >
            เพิ่มแอดมิน
         </Button>
         <Container>
            {isAdd && (
               <div className='form-block'>
                  <Form
                     name='basic'
                     labelCol={{
                        span: 8,
                     }}
                     wrapperCol={{
                        span: 16,
                     }}
                     initialValues={{
                        remember: true,
                     }}
                     onFinish={onFinish}
                     onFinishFailed={onFinishFailed}
                     autoComplete='off'
                  >
                     <Form.Item
                        label='ชื่อที่แสดง'
                        name='username'
                        rules={[
                           {
                              required: true,
                              message: 'กรุณากรอกชื่อที่แสดง!',
                           },
                        ]}
                     >
                        <Input />
                     </Form.Item>
                     <Form.Item
                        label='รหัสผ่าน'
                        name='password'
                        rules={[
                           {
                              required: true,
                              message: 'กรุณากรอกชื่อที่แสดงรหัสผ่าน!',
                           },
                        ]}
                     >
                        <Input.Password />
                     </Form.Item>
                     <Form.Item
                        label='ชื่อจริง'
                        name='firtsName'
                        rules={[
                           {
                              required: true,
                              message: 'กรุณากรอดชื่อจริง!',
                           },
                        ]}
                     >
                        <Input />
                     </Form.Item>
                     <Form.Item
                        label='นามสกุล'
                        name='lastName'
                        rules={[
                           {
                              required: true,
                              message: 'กรุณากรอกนามสกุล!',
                           },
                        ]}
                     >
                        <Input />
                     </Form.Item>
                     <Form.Item
                        wrapperCol={{
                           offset: 8,
                           span: 16,
                        }}
                     >
                        <Button type='primary' htmlType='submit'>
                           Submit
                        </Button>
                     </Form.Item>
                  </Form>
               </div>
            )}
            <Table columns={columns} dataSource={adminsUser} />
         </Container>
         <Modal
            title='แก้ไขข้อมูลแอดมิน'
            open={editModal}
            onCancel={() => {
               setEditModal(false);
               setAdminId({});
               form.resetFields(); // Reset form fields
           //    window.location.reload()
            }}
            footer={false}
         >
            <div>
               <h5>
                  <span>รหัสแอดมิน :</span> <b>{adminId.userId}</b>
               </h5>
               <Form
                  form={form}
                  name='edit admin'
                  labelCol={{
                     span: 6,
                  }}
                  wrapperCol={{
                     span: 18,
                  }}
                  onFinish={onFinishEditAdmin}
                  initialValues={{
                     username: adminId.username,
                     password: adminId.pass,
                  }}
                  autoComplete='off'
               >
                  <Form.Item name='username' label='ชื่อแอดมิน'>
                     <Input />
                  </Form.Item>
                  <Form.Item name='password' label='รหัสผ่าน'>
                     <Input />
                  </Form.Item>
                  <Button
                     type='primary'
                     htmlType='submit'
                     block
                     size='large'
                     className='btn-secondary mt-3'
                  >
                     ยืนยัน
                  </Button>
               </Form>
            </div>
         </Modal>
      </DefaultLayout>
   );
};

export default AdminsPage;

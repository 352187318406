import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Image,
  InputNumber,
  Tooltip,
  Col,
  Row,
  Card,
  Table,
  Switch,
} from 'antd';

import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BsCheckLg, BsFillCartPlusFill } from 'react-icons/bs';
import { TbPackage } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading';
import {
  createProduct,
  deleteProduct,
  getProducts,
  reset,
  updateProduct,
  updatePromotion,
} from '../features/product/productSlice';
import DefaultLayout from '../components/DefualtLayout';
import { Link, useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { MdOutlineClose } from 'react-icons/md';
import Resizer from 'react-image-file-resizer'

const ProductListPage = () => {
  const [addEditModalOpen, setAddEditModalOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [media, setMedia] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [typeSelect,SetTypeSelect ] = useState('')

  const { userInfo } = useSelector((state) => state.auth);
  const {
     products,
     isLoading,
     isError,
     isSuccess,
     getLoading,
     getSuccess,
     deleteSuccess,
     createSuccess,
  } = useSelector((state) => state.product);

  const handleTypeChange = (value) => {
   console.log(`selected ${value}`);
 };


  useEffect(() => {

   if(userInfo.isSuperAdmin){
      navigate('/admin/dashboard')
   }

     dispatch(getProducts());

     return () => {
        dispatch(reset());
     };
  }, [dispatch, isSuccess, createSuccess]);

  const onFinish = async (values) => {
     if (media !== null) values.picUrl = media;
     console.log(values);

     if (editItem === null) {
        dispatch(createProduct(values));
        setMedia(null);
        // window.location.reload();
     } else {
        values._id = editItem._id;
        console.log(values);
        dispatch(updateProduct(values));

        setMedia(null);
        //  window.location.reload();
     }
  };

  const handleProductImageUpload = (e) => {
     const file = e.target.files[0];

     if (file) {
        try {
           Resizer.imageFileResizer(
              file,
              400,
              400,
              'JPEG',
              100,
              0,
              (uri) => {
                 setMedia(uri);
              },
              'base64'
           );
        } catch (err) {
           console.log(err);
        }
     }
  };

  const handleSwitchChange = (record) => {
     dispatch(updatePromotion(record._id));
     console.log(record);
     //window.location.reload();
  };

  const deleteHandler = (id) => {
     let resultConfirm = window.confirm('ต้องการลบสินค้า ?');
     if (resultConfirm == true) {
        console.log('Is Yes');
        dispatch(deleteProduct(id));
     } else {
        console.log('Is Cancle');
     }
  };

  const column = [
     {
        title: 'รูปภาพ',
        dataIndex: 'picUrl',
        render: (picUrl) => (
           <img
              src={picUrl.url}
              alt=''
              style={{ objectFit: 'cover' }}
              height='100'
              width='100'
           />
        ),
     },
     {
        title: 'ชื่อสินค้า',
        dataIndex: 'name',
     },
     {
      title: 'ประเภท',
      dataIndex: 'type',
   },
     {
        title: 'รายละเอียดสินค้า',
        dataIndex: 'desc',
     },
     {
      title: 'จำนวนคงเหลือ',
      dataIndex: 'qty',
   },
     {
        title: 'ราคาสินค้า',
        dataIndex: 'price',
     },
   
     {
        title: 'pv',
        dataIndex: 'pv',
     },
     {
        title: '',
        dataIndex: '_id',
        render: (id, record) => (
           <div className='d-flex justify-content-around'>
             
              <Button
                 className='btn-warning'
                 onClick={() => {
                    setEditItem(record);
                    setAddEditModalOpen(true);
                 }}>
                 {' '}
                 แก้ไข
              </Button>
              <Button
                 className='btn-danger'
                 onClick={() => deleteHandler(record._id)}>
                 {' '}
                 ลบ
              </Button>
           </div>
        ),
     },
  ];

  return (
     <DefaultLayout>
        {isLoading ? (
           <Loading />
        ) : (
           <Container>
              <div>
                 <div
                    className='d-flex'
                    style={{ justifyContent: 'space-evenly' }}>
                    <Button
                       type='primary'
                       htmlType='submit'
                       onClick={() => setAddEditModalOpen(true)}>
                       เพิ่มสินค้า
                    </Button>

                    <Button
                       type='primary'
                       htmlType='submit'
                       onClick={() => navigate('/admin/stocks')}
                       >
                       จัดการสินค้าคงคลัง
                    </Button>
                  
                 </div>
                 <br />
                 <br />
                
                 <br />
                 <div>
                    <Table columns={column} dataSource={products} />
                 </div>
              </div>
           </Container>
        )}
        {addEditModalOpen && (
           <Modal
              title={`${editItem !== null ? 'Edit Item' : 'Add new Item'}`}
              visible={addEditModalOpen}
              footer={false}
              onCancel={() => {
                 setEditItem(null);
                 setAddEditModalOpen(false);
              }}>
              <Form
                 initialValues={editItem}
                 layout='vertical'
                 onFinish={onFinish}>
                 {editItem !== null && (
                    <div className='d-flex justify-content-center my-3'>
                       <Image
                          className='m-auto'
                          preview={false}
                          height={200}
                          width={200}
                          src={editItem.picUrl.url}
                       />
                    </div>
                 )}

                 <Form.Item name='name' label='ชื่อ'>
                    <Input />
                 </Form.Item>

                 <Form.Item name='type' label='ประเภท'>
                   <Select 
                   defaultValue='กรุณาเลือกประเภท'
                   onChange={handleTypeChange}
                   options={[
                     {
                        value: 'Tester',
                        label: 'Tester',
                      },
                     {
                        value: 'Member',
                        label: 'Member',
                      },
                      {
                        value: 'Holder',
                        label: 'Holder',
                      },
                      {
                        value: 'Sell',
                        label: 'Sell',
                      },
                   ]}
                   />
                 </Form.Item>

                 <Form.Item name='price' label='ราคา'>
                    <InputNumber style={{ width: '100%' }} />
                 </Form.Item>

                 <Form.Item name='pv' label='คะแนน PV'>
                    <InputNumber style={{ width: '100%' }} />
                 </Form.Item>

                 <Form.Item name='qty' label='จำนวนคงเหลือ'>
                    <InputNumber style={{ width: '100%' }} />
                 </Form.Item>

                 <Form.Item name='desc' label='รายละเอียดสินค้า'>
                    <Input />
                 </Form.Item>

                 <input
                    id='upload'
                    name='imgUpload'
                    accept='image/*'
                    type='file'
                    onChange={handleProductImageUpload}
                 />
                 <div className='d-flex justify-content-center my-3'>
                    <Image
                       className='m-auto'
                       preview={false}
                       height={100}
                       width={100}
                       src={media}
                    />
                 </div>

                 <div className='d-flex justify-content-end'>
                    <Button
                       loading={isLoading}
                       htmlType='submit'
                       type='primary'>
                       {' '}
                       SAVE
                    </Button>
                 </div>
              </Form>
           </Modal>
        )}
     </DefaultLayout>
  );
};

const DeleteButton = styled.p`
  color: rgb(202, 0, 19);
  fontsize: 36px;
  margin-top: 5px;

  width: 100%;
  height: 100%;
  transition: all 0.2s ease-out;
  &:hover {
     transform: scale(1.1);
  }
  &:active {
     color: white;
  }
`;
const EditButton = styled.p`
  color: #0092ca;
  fontsize: 36px;
  margin-top: 5px;

  width: 100%;
  height: 100%;
  transition: all 0.2s ease-out;
  &:hover {
     transform: scale(1.1);
  }
  &:active {
     color: white;
  }
`;

export default ProductListPage;

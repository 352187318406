import { Button, DatePicker, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { getUserRegister, reset } from '../features/users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import Loading from './Loading';
import MoonLoader from 'react-spinners/MoonLoader'

const ExportUserRegister = () => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [note, setNote] = useState('สมัครสมาชิก');
   const [dateS, SetDateS] = useState('');

   const { usersRegister, isLoading } = useSelector((state) => state.user);

   const dispatch = useDispatch();

   useEffect(() => {
       return () => {
        dispatch(reset())
       }
   }, [dispatch])

   const showModal = () => {
      setIsModalOpen(true);
   };
   const handleOk = () => {
      let data = {};
      data.date = dateS;
      data.note = note;
      dispatch(getUserRegister(data));

      //  setIsModalOpen(false);
   };
   const handleCancel = () => {
      setIsModalOpen(false);
   };

   const onChange = (dateString) => {
      SetDateS(dateString);
   };

   const handleChange = (value) => {
      setNote(value);
   };

   const headers = [
    { label: 'รหัสสมาชิก', key: 'รหัสสมาชิก' },
    { label: 'ชื่อ', key: 'ชื่อจริง', width: 30 },
    { label: 'นามสกุล', key: 'นามสกุล', width: 30 },
    { label: 'เลขบัตรประชาชน', key: 'บัตรประชาชน' },
    { label: 'เบอร์โทร', key: 'เบอร์' },
    { label: 'จำนวนรหัส', key: 'จำนวนรหัส' },
    { label: 'แพ็คเกจ', key: 'แพ็คเกจ' },
    { label: 'วันที่สมัคร', key: 'วันที่สมัคร' },
    { label: 'พีวี', key: 'พีวี' },
    { label: 'โน๊ต', key: 'โน๊ต' },
    { label: 'วันที่จากบิล', key: 'วันที่จากบิล' },
    { label: 'ที่อยู่', key: 'ที่อยู่' },
    { label: 'จังหวัด', key: 'จังหวัด' },
    { label: 'อำเภอ', key: 'อำเภอ' },
    { label: 'ตำบล', key: 'ตำบล' },
    { label: 'รหัสไปรษณี', key: 'รหัสไปรษณี' },
  

 ];


   return (
      <>
         <div>
            <Button type='primary' onClick={showModal}>
               Export สมาชิก
            </Button>
         </div>
         <Modal
            title='Export Data'
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
         >
            <div>
               <Select
                  defaultValue='สมัครสมาชิก'
                  style={{
                     width: 120,
                  }}
                  onChange={handleChange}
                  options={[
                     {
                        value: 'สมัครสมาชิก',
                        label: 'สมัครสมาชิก',
                     },
                     {
                        value: 'ท็อปอัพรหัส',
                        label: 'ท็อปอัพรหัส',
                     },
                     {
                        value: 'ต่ออายุรหัส',
                        label: 'ต่ออายุรหัส',
                     },
                  ]}
               />
               <DatePicker onChange={onChange} />
            </div>
            <br />
            <br />
            {isLoading && (
              <>
<MoonLoader />
              </>
            )}
            <div>
               {usersRegister.length > 1 && (
                  <Button
                  loading={isLoading}
                  style={{ background: 'green', color: 'white' }}>
                     <CSVLink
                        filename={`สรุปข้อมูลสมาชิกวันที่${dateS}.csv`}
                        data={usersRegister}
                        headers={headers}
                     >
                        Export Data
                     </CSVLink>
                  </Button>
               )}
            </div>
         </Modal>
      </>
   );
};

export default ExportUserRegister;

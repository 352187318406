import { Statistic, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { getComMobile } from '../features/commission/commissionSlice';
import { getSetting } from '../features/setting/settingSlice';
import DefaultLayout from '../components/DefualtLayout';
import {
   getCommissionPerson,
   getCurrentPromotionByCardId,
} from '../features/adminFeatures/commission/commissionAdminSlice';

const ExportBonusPerson = () => {
   const [loadingPage, setLoadingPage] = useState(true);
   const dispatch = useDispatch();

   const location = useLocation();


   const { cardId, date } = useParams();


   const dateString = moment(date).format('DD/MM/YYYY');

   const { userInfo } = useSelector((state) => state.auth);
   const { settings } = useSelector((state) => state.setting);
   const {
      reccommendCommsion,
      comMobile,
      isError,
      isSuccess,
      message,
      isLoading,
   } = useSelector((state) => state.commission);
   const { commissions } = useSelector((state) => state.commissionAdmin);

   console.log('ccc' , commissions[0]?.name);

   // commissions.forEach(r => { return {...r.toObject()} })
   // console.log(commissions.map((e) => {
   //    return {
   //       ...e.toObject()
   //    }
   // }));
   //  return { ...e.toObject() };

   let keyPrice = null;
   let transferFee = null;
   let tax = null;
   if (settings) {
      keyPrice = settings
         .filter((obj) => obj.name.includes('keyPrice'))
         .map((obj) => obj.number);

      transferFee = settings
         .filter((obj) => obj.name.includes('transferFee'))
         .map((obj) => obj.number);

      tax = settings
         .filter((obj) => obj.name.includes('tax'))
         .map((obj) => obj.number);

      transferFee.toString();
      tax.toString();
   }

   let transferFeeInt = parseInt(transferFee);
   let taxInt = parseInt(tax);

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      dispatch(getCommissionPerson({ cardId, dateString }));
      //  if (userInfo.memberKeys === 15) dispatch(getComMobile());
      dispatch(getComMobile());
      dispatch(getCurrentPromotionByCardId({ cardId, date }));
      // dispatch(getRecommendCommission());
      dispatch(getSetting());
   }, []);

   const { result, result2, tUser, tUser2 } = reccommendCommsion;

   const { commission, user } = commissions;

   let mm = [];
   let re = [];

   let rr = [];
   let rr2 = [];
   let rr3 = [];

   if (commission && result) {
      rr = result.map((r1) => {
         const rr2 = result2.find(
            (r2) =>
               r1.date.toString().substring(0, 10) ===
               r2.date.toString().substring(0, 10)
         );
         return { ...r1, r2: rr2 };
      });

      rr.filter(function (element) {
         return element.r2 !== undefined;
      });

      rr.filter((e) => {
         return Object.keys(e).forEach((key) => {
            if (e[key] === undefined) {
               return e[key] === 0;
            }
         });
      });

      rr2 = rr.filter((e) => {
         return e.r2 !== undefined;
      });

      rr3 = rr.map((e) => {
         // eslint-disable-next-line no-sequences
         return {
            date: e.date,
            amount2: e.r2 ? e.amount2 + e.r2.amount2 : e.amount2,
            amount22: e.amount2,
            amount23: e.r2 ? e.r2.amount2 : 0,
         };
      });

      const tt1 = rr3.filter((e) => {
         return commission.every((f) => {
            return (
               f.date.toString().substring(0, 10) !==
               e.date.toString().substring(0, 10)
            );
         });
      });

      const position = user.position;

      for (let i = 0; i < commission.length; i++) {
         if (result) {
            mm.push({
               ...commission[i],
               position,
               ...rr3.find(
                  (itmInner) =>
                     itmInner.date.toString().substring(0, 10) ===
                     commission[i].date.toString().substring(0, 10)
               ),
            });
         }
      }

      if (result) {
         Array.prototype.push.apply(mm, tt1);
      }
   }

   const comMobile2 = comMobile.flat();
   const newCommissions = commissions.map((i) => {
      return {
         ...i,
         comMobile: i.mobileCommission,
         netSun: (i.amount + i.mobileCommission) - ((i.amount * taxInt) / 100 - 30),
         fee: (i.amount * taxInt) / 100 + 30 ,
      };
   });


   const columns = [
      // {
      //    title: 'วันที่',
      //    dataIndex: 'date',
      //    key: 'date',
      //    render: (text) => <div> {text.toString().substring(0, 10)} </div>,
      // },
      {
         title: 'รหัสสมาชิก',
         dataIndex: 'userId',
         key: 'userId',
      },
      {
         title: 'ตำแหน่ง',
         dataIndex: 'position',
         key: 'position',
         render: (record) => (
            <>
               <p> {record} </p>
            </>
         ),
      },
      {
         title: 'STEP',
         dataIndex: ['stepTo, stepFrom'],
         render: (_, row) => (
            <>
               {row['stepFrom'] === 0 ? row['stepFrom'] + 1 : row['stepFrom']}
               <span>
                  {' '}
                  <BiRightArrowAlt />{' '}
               </span>{' '}
               {row['stepTo']}
            </>
         ),
      },
      {
         title: 'ไบนารี่',
         dataIndex: 'amount',
         key: 'amount',
         render: (record) => (
            <>
               {record ? (
                  <>
                     {/* <span className='text-primary mr-3'>{record}</span>
                     <span>฿</span> */}
                     <Statistic
                        value={record}
                        suffix='฿'
                        valueStyle={{
                           color: '#3f8600',
                        }}
                     />
                  </>
               ) : (
                  <>
                     <p>-</p>
                  </>
               )}
            </>
         ),
      },
      {
         title: 'โมบาย',
         dataIndex: 'mobileCommission',
         key: 'mobileCommission'
        
      },
  
      {
         title: 'ค่าดำเนินการ',
         dataIndex: ['amount', 'mobileCommission'],
         render: (_, row) => (
            <>
               {row['mobileCommission'] ? (
                  <>
                     <span className='text-danger'>
                        {' '}
                        -{' '}
                        {/* {Math.floor(
                           ((row['amount'] +
                              (row['amount2'] * 5) / 100 -
                              (row['amount'] * spPercentInt) / 100) *
                              taxInt) /
                              100 +
                              transferFeeInt
                        )} */}
                        {Math.floor(
                           ((row['amount'] + row['mobileCommission']) * taxInt) / 100 -30
                        )}
                     </span>
                     <span>฿</span>

                     {/* -{(record * spPercentInt) / 100} */}
                  </>
               ) : (
                  <>
                     <span className='text-danger'>
                        {' '}
                        -{' '}
                        {Math.floor(
                           (row['amount'] * taxInt) / 100  +30
                        )}
                     </span>
                     <span>฿</span>
                  </>
               )}
            </>
         ),
      },

      {
         title: 'สุทธิ',
         dataIndex: ['amount', 'mobileCommission'],
         render: (_, row) => (
            <>
            {row['mobileCommission'] ? (
               <>
                  <span className=''>
                
                     {Math.floor(
                      (row['amount'] + row['mobileCommission']) -  ((row['amount'] + row['mobileCommission']) * taxInt) / 100 
                     )}
                  </span>
                  <span>฿</span>

                  {/* -{(record * spPercentInt) / 100} */}
               </>
            ) : (
               <>
                 <span className=''>
                
                {Math.floor(
                 (row['amount'] ) -  (row['amount'] * taxInt) / 100 -30
                )}
             </span>
             <span>฿</span>
               </>
            )}
         </>
         )
      },
      {
         title: 'คำนวณ',
         dataIndex: 'status',
      },
   ];

   return (
      <DefaultLayout>
         <h3>สรุปค่าคอมมิชชั่น {commissions && commissions[0]?.name} </h3>
         <div className='d-flex'>
            <h3>วันที่ :</h3>
            <h3> {date} </h3>
         </div>
         {loadingPage ? (
            <>
               <Loading />{' '}
            </>
         ) : (
            <>
               <Table
                  // key={mergedMM._id}
                  // summary={(commissions) => {
                  //    let totalAmount = 0;
                  //    commissions.forEach(({ totalSum }) => {
                  //       totalAmount += totalSum
                  //    })
                  //    return (
                  //       <>
                  //         <Table.Summary.Row>
                  //             <Table.Summary.Cell>
                  //                {' '}
                  //                <b> รวม</b>{' '}
                  //             </Table.Summary.Cell>
                  //             <Table.Summary.Cell></Table.Summary.Cell>
                  //             <Table.Summary.Cell></Table.Summary.Cell>
                  //             <Table.Summary.Cell type='danger'>
                  //                <p>
                  //                   {' '}
                  //                   <Statistic suffix='฿' value={totalAmount} />
                  //                </p>
                  //             </Table.Summary.Cell>
                  //          </Table.Summary.Row>
                  //       </>
                  //    )
                  // }}
                  columns={columns}
                  dataSource={newCommissions}
                  scroll={{
                     x: 1500,
                  }}
                  rowKey='id'
               />
            </>
         )}
      </DefaultLayout>
   );
};

export default ExportBonusPerson;

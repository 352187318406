import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Layout, Menu, Image, Drawer, Dropdown, Space, Row, Col } from 'antd';
import {
   AiOutlineHome,
   AiOutlineMenuFold,
   AiOutlineMenuUnfold,
   AiOutlineLogout,
   AiOutlineAppstore,
   AiOutlineBranches,
} from 'react-icons/ai';

import { logout } from '../features/auth/authSlice';
import {
   BiDollar,
   BiTransfer,
   BiMinus,
   BiTime,
   BiExport,
} from 'react-icons/bi';
import { HiClipboardList, HiOutlineShare, HiUserAdd } from 'react-icons/hi';
import {
   FaMoneyCheckAlt,
   FaRegUserCircle,
   FaKey,
   FaHistory,
   FaUsers,
   FaListUl,
   FaUser,
} from 'react-icons/fa';
import styled from 'styled-components';
import {
   MdAccountBalanceWallet,
   MdOutlineSettings,
   MdSupportAgent,
} from 'react-icons/md';
import Logo from '../assets/img/LOGO.png';
import { BsFillCartPlusFill, BsLine } from 'react-icons/bs';
import LINE from '../assets/img/LINE_SIAMPRAI.jpg';
import { useMediaQuery } from 'react-responsive';

import man from '../assets/icon/man.png';
import woman from '../assets/icon/woman.png';
import admin from '../assets/icon/user1.png';
import { RiLuggageDepositFill, RiMoneyDollarCircleFill } from 'react-icons/ri';
import axios from 'axios';
import Cookies from 'js-cookie';
import { TbReportAnalytics, TbReportMoney } from 'react-icons/tb';
import { getAdminCenterPV, reset } from '../features/ewallet/ewalletSlice';
import commaNumber from 'comma-number';

const { Header, Sider, Content } = Layout;

const DefaultLayout = (props) => {
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { userInfo } = useSelector((state) => state.auth);
   const { ewallet } = useSelector((state) => state.ewallet);

   const token = Cookies.get('token');

   const cart = useSelector((state) => state.cart);

   const { cartItems } = cart;

   const [current, setCurrent] = useState('1');

   const [collapsed, setCollapsed] = useState(false);
   const toggleCollapsed = () => {
      setCollapsed(!collapsed);
   };

   const isMobile = useMediaQuery({ maxWidth: 750 });

   useEffect(() => {
      const tokenExpired = async () => {
         const res = await axios.get(
            //           'http://localhost:8000/api/users/tokenExpired',
            //        '//api.admin-siamprai.com/api/users/tokenExpired',
            {
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }
         );

         if (res.data.message == 'token expired') {
            //    dispatch(logout());
            //     navigate('/login');
         }
      };
      //    tokenExpired();

      // if(token === undefined){
      //    navigate('/login');
      // }

      //  console.log('test expired');

      dispatch(getAdminCenterPV());

      if (isMobile) {
         setCollapsed(!collapsed);
      }

      return () => {
         dispatch(reset());
      };
   }, [dispatch, isMobile, cartItems, dispatch]);

   // const ScreenWidth = () => {
   //    if (window.innerWidth < 720) {
   //       setCollapsed(!collapsed);
   //       console.log(collapsed);
   //    } else {
   //       setCollapsed(collapsed);
   //       console.log(collapsed);
   //    }
   // };

   // useEffect(() => {
   //    window.addEventListener('resize', ScreenWidth);
   //    return () => window.removeEventListener('resize', ScreenWidth);
   // }, []);

   // CART AND DRAWER

   const [visible, setVisible] = useState(false);

   const showDrawer = () => {
      setVisible(true);
   };

   const onClose = () => {
      setVisible(false);
   };

   const handleResize = () => {
      if (window.innerWidth < 520 || window.innerWidth < 780) {
         setCollapsed(true);
      } else {
         setCollapsed(false);
      }
   };

   // create an event listener
   useEffect(() => {
      window.addEventListener('resize', handleResize);
   });

   const logoutHandler = () => {
      dispatch(logout());
      navigate('/login');
   };

   const menu = (
      <Menu
         items={[
            {
               key: '1',
               label: (
                  <>
                     <Row>
                        <Col span={8}>
                           {' '}
                           <Image
                              style={{
                                 marginTop: '20px',
                              }}
                              src={admin}
                              preview={false}
                              width={55}
                           />
                           {/* <FaRegUserCircle
                              style={{ fontSize: '55px', marginTop: '20px' }}
                           /> */}
                        </Col>
                        <Col span={14}>
                           <p> {userInfo.username}</p>
                           <p>รหัส:{userInfo.userId} </p>
                        </Col>
                     </Row>
                     <hr />
                  </>
               ),
            },

            {
               key: '5',
               danger: true,
               label: (
                  <>
                     <p
                        onClick={logoutHandler}
                        style={{
                           borderTop: '1px solid gray',
                           marginBottom: '10px !important',
                        }}
                     >
                        ออกจากระบบ
                     </p>
                     <hr />
                  </>
               ),
            },
         ]}
      />
   );

   return (
      <div>
         <Layout>
            <Sider
               trigger={null}
               breakpoint='md'
               collapsedWidth={0}
               collapsible
               collapsed={collapsed}
            >
               <div className='logo p-2 mb-2'>
                  <Image src={Logo} preview={false} />
               </div>
               <Menu
                  defaultSelectedKeys={window.location.pathname}
                  defaultOpenKeys={window.location.pathname}
                  mode='inline'
               >
                  <Menu.Item key='/admin/dashboard' icon={<AiOutlineHome />}>
                     <Link to='/admin/dashboard'>แดชบอร์ด</Link>
                  </Menu.Item>
                  <Menu.Item key='/admin/memberRegistration' icon={<FaUser />}>
                     <Link to='/admin/memberRegistration'>ทะเบียนสมาชิก</Link>
                  </Menu.Item>
                  <Menu.Item
                     key='/admin/stocksHistories'
                     icon={<TbReportAnalytics />}
                  >
                     <Link to='/admin/stocksHistories'>ยอดขาย</Link>
                  </Menu.Item>
                  <Menu.Item
                     key='/admin/exportBonusSelectDay'
                     icon={<TbReportMoney />}
                  >
                     <Link to='/admin/exportBonusSelectDay'>คอมมิชชั่นไทย</Link>
                  </Menu.Item>

                  <Menu.Item
                     key='/admin/exportBonusSelectDayKhmer'
                     icon={<TbReportMoney />}
                  >
                     <Link to='https://admin-siampraimem.com/'>
                        ระบบกัมบูชา
                     </Link>
                  </Menu.Item>

                  <Menu.Item
                     key='/admin/summaryCommissionUsersPerMonth'
                     icon={<TbReportMoney />}
                  >
                     <Link to='/admin/summaryCommissionUsersPerMonth'>
                        สรุปคอมมิชชั่นรายเดือนสมาชิก
                     </Link>
                  </Menu.Item>

                  <Menu.Item
                     key='/admin/deposits'
                     icon={<RiLuggageDepositFill />}
                  >
                     <Link to='/admin/deposits'> รายการเติม PV</Link>
                  </Menu.Item>

                  <Menu.Item key='/admin/orderLists' icon={<BiExport />}>
                     <Link to='/admin/orderLists'> รายการขายสินค้า </Link>
                  </Menu.Item>

                  {userInfo.userId === '1288872' ? (
                     <Menu.Item key='/admin/admins' icon={<HiOutlineShare />}>
                        <Link to='/admin/admins'> รายการแอดมิน </Link>
                     </Menu.Item>
                  ) : null}

                  <Menu.Item key='/admin/centers' icon={<AiOutlineBranches />}>
                     <Link to='/admin/centers'> รายการเซ็นเตอร์ </Link>
                  </Menu.Item>
                  {userInfo.isSuperAdmin ? null : (
                     <Menu.Item key='/admin/productlist' icon={<FaListUl />}>
                        <Link to='/admin/productlist'> รายการสินค้า </Link>
                     </Menu.Item>
                  )}

                  <Menu.Item key='/admin/setting' icon={<MdOutlineSettings />}>
                     <Link to='/admin/setting'>ตั้งค่า เปอร์เซนเทจ</Link>
                  </Menu.Item>
                  {/* <Menu.Item key='/resetpassword' icon={<FaKey />}>
                     <Link to='/resetpassword'>เปลี่ยนรหัสผ่าน</Link>
                  </Menu.Item> */}
                  <Menu.Item
                     key='/logout'
                     icon={<AiOutlineLogout />}
                     onClick={logoutHandler}
                  >
                     ออกจากระบบ
                  </Menu.Item>
               </Menu>
            </Sider>

            <Layout className='site-layout'>
               <Header
                  className='d-flex site-layout-background'
                  style={{ padding: 10 }}
               >
                  {React.createElement(
                     collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
                     {
                        className: 'trigger',
                        onClick: toggleCollapsed,
                     }
                  )}

                  <Space>
                     {/* <h5>
                        <AiOutlineShoppingCart />
                     </h5>
                     <span className='text-danger'>
                        <p>{cart.cartItems.length}</p>{' '}
                     </span> */}

                     {/* <Dropdown overlay={menuCart}>
                        <a onClick={(e) => e.preventDefault()}>
                           <Badge count={cart.cartItems.length}>
                              <Avatar
                                 style={{ color: 'black' }}
                                 shape='circle'
                                 icon={<AiOutlineShoppingCart />}
                                 size='large'
                              />
                           </Badge>
                        </a>
                     </Dropdown> */}

                     <span>
                        <b>PV : {commaNumber(ewallet.pv)} </b>
                     </span>

                     <Dropdown overlay={menu}>
                        <a onClick={(e) => e.preventDefault()}>
                           <h4 className='mx-2 my-1'>
                              {' '}
                              {/* <Image src={user} preview={false} width={24} />
                              <Image src={user1} preview={false} width={24} />
                              <Image src={user2} preview={false} width={24} /> */}
                              <>
                                 {' '}
                                 <Image
                                    src={admin}
                                    preview={false}
                                    width={24}
                                 />
                              </>
                           </h4>
                        </a>
                     </Dropdown>
                  </Space>
               </Header>
               <div className=''>
                  {' '}
                  <Content
                     className='site-layout-background'
                     style={{
                        margin: '10px',
                        padding: 24,
                        minHeight: '80vh',
                     }}
                  >
                     {props.children}
                  </Content>
               </div>
            </Layout>
         </Layout>
      </div>
   );
};

export default DefaultLayout;

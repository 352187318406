import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ewalletService from './ewalletService';
import Cookies from 'js-cookie';

const initialState = {
   ewallets: [],
   ewallet: {},
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: '',
};

// UPDATE EWALLET
export const updateEwallet = createAsyncThunk(
   'ewallet/updateEwallet',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.updateEwallet(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// ADMIN UPDATE EWALLET
export const AdminUpdateEwallet = createAsyncThunk(
   'ewallet/adminUpdateEwallet',
   async (data, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.AdminUpdateEwallet(data, token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getByMonth = createAsyncThunk(
   'ewallet/getByMonth',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.getByMonth(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getEwallet = createAsyncThunk(
   'ewallet/getEwallet',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.getEwallet(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getAdminCenterPV = createAsyncThunk(
   'ewallet/getAdminCenterPV',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.getAdminCenterPV(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);



export const getCenterDeposit = createAsyncThunk(
   'ewallet/getCenterDeposit',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.getCenterDeposit(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

export const getCenterTransfer = createAsyncThunk(
   'ewallet/getCenterTransfer',
   async (_, thunkAPI) => {
      try {
         const token = Cookies.get('token');
         return await ewalletService.getCenterTransfer(token);
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

const ewalletSlice = createSlice({
   name: 'ewallet',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(updateEwallet.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateEwallet.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallet = action.payload;
         })
         .addCase(updateEwallet.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(AdminUpdateEwallet.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(AdminUpdateEwallet.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallet = action.payload;
         })
         .addCase(AdminUpdateEwallet.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getEwallet.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getEwallet.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallet = action.payload;
         })
         .addCase(getEwallet.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getAdminCenterPV.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAdminCenterPV.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallet = action.payload;
         })
         .addCase(getAdminCenterPV.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getCenterTransfer.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCenterTransfer.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallets = action.payload;
         })
         .addCase(getCenterTransfer.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getCenterDeposit.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getCenterDeposit.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallets = action.payload;
         })
         .addCase(getCenterDeposit.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         .addCase(getByMonth.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getByMonth.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.ewallets = action.payload;
         })
         .addCase(getByMonth.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         });
   },
});

export const { reset } = ewalletSlice.actions;
export default ewalletSlice.reducer;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Checkbox, Form, Image, Input, message } from 'antd';
import { AiOutlineUser, AiOutlineLock } from 'react-icons/ai';
import { Container } from 'react-bootstrap';
import Logo from '../assets/img/LOGO.png';
import { useNavigate } from 'react-router-dom';
import { login, reset } from '../features/auth/authSlice';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const LoginPage = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const toastId = React.useRef(null);
   const token = Cookies.get('token');

   const {
      userInfo,
      isLoading,
      isError,
      isSuccess,
      message: msgShow,
   } = useSelector((state) => state.auth);

   useEffect(() => {
      if (isError) {
         message.error(msgShow);
      }

      if (userInfo && token) {
         navigate('/');
      } else {
         navigate('/login');
      }

      if (userInfo && token && userInfo.isThai) {
         navigate('/admin/dashboard');
      } else if (userInfo && token && userInfo.isKhmer) {
         navigate('/admin/dashboard/kh');
      }

      dispatch(reset());
   }, [userInfo, isError, isSuccess, message, navigate, dispatch]);

   const onFinish = (values) => {
      dispatch(login(values));
   };

   return (
      <div className='Login d-flex justify-content-center'>
         <div className='auth-block'>
            <Container>
               <div className='text-center'>
                  <span className='db d-block my-2 mb-4'>
                     <Image src={Logo} width={150} preview={false} />
                     <h5 className='my-4'>
                        <b>Login</b>
                     </h5>
                  </span>
               </div>
               <Form
                  name='normal_login'
                  className='login-form'
                  initialValues={{
                     remember: true,
                  }}
                  onFinish={onFinish}
               >
                  <Form.Item
                     name='userId'
                     rules={[
                        {
                           required: true,
                           message: 'กรุณากรอกรหัสสมาชิก!',
                        },
                     ]}
                  >
                     <Input
                        style={{
                           fontSize: '18px',
                        }}
                        prefix={
                           <AiOutlineUser className='site-form-item-icon' />
                        }
                        placeholder='รหัสสมาชิก'
                     />
                  </Form.Item>
                  <Form.Item
                     name='password'
                     rules={[
                        {
                           required: true,
                           message: 'กรุณากรอกรหัสผ่าน!',
                        },
                     ]}
                  >
                     <Input.Password
                        style={{
                           fontSize: '18px',
                        }}
                        prefix={
                           <AiOutlineLock className='site-form-item-icon' />
                        }
                        type='password'
                        placeholder='รหัสผ่าน'
                     />
                  </Form.Item>

                  <Form.Item>
                     <Button
                        style={{ position: 'relative', zIndex: '9999' }}
                        type='primary'
                        htmlType='submit'
                        block
                        size='large'
                        className='login-form-button mt-2 btn-secondary'
                     >
                        เข้าใช้งานระบบ
                     </Button>
                  </Form.Item>
               </Form>
               <div
                  style={{
                     position: 'absolute',
                     bottom: '0',
                     left: '0',
                     right: '0',
                  }}
               >
                  <h5 className='my-2 text-center' style={{ fontSize: '10px' }}>
                     <b style={{ color: 'gray' }}>
                        Copyright © by B.A.T INTER GROUP COMPANY LIMITED
                     </b>{' '}
                  </h5>
               </div>
            </Container>
         </div>
      </div>
   );
};

export default LoginPage;

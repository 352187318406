import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefualtLayout';
import { useDispatch, useSelector } from 'react-redux';
import {
   addProdctAmount,
   getProducts,
   reset,
} from '../features/product/productSlice';
import {
   Button,
   Card,
   Col,
   ConfigProvider,
   Form,
   Input,
   InputNumber,
   Modal,
   Row,
   Table,
} from 'antd';
import moment from 'moment'

const Products = () => {
    const dispatch = useDispatch();

    const { userInfo } = useSelector((state) => state.auth);
    const {
       products,
       isLoading,
       isError,
       isSuccess,
       getLoading,
       getSuccess,
       deleteSuccess,
       createSuccess,
    } = useSelector((state) => state.product);
 
    const products2 = products.filter((e) => e.type === 'Holder');

    useEffect(() => {
        dispatch(getProducts());
  
        return () => {
           dispatch(reset());
        };
     }, [dispatch, isSuccess, createSuccess]);

     const today = new Date()


  

  return (
    <>
    <div
    >
        <Row>
            <Col sm={24} xs={24} md={24} lg={24} xl={24}>
            <div
              className='main-shadow text-center'
              style={{backgroundColor: 'white', padding: '14px',borderRadius: '20px'}}
            >
      <h5 className='mb-5 mt-2'>
                     <b>
                        จำนวนสินค้าคงเหลือ
                     </b>
                  </h5>

                  <Row gutter={10} justify='center' >
    {products2.map((e) => {
        return (
            <>
            <Col sm={12} xs={12} md={12} lg={8} xl={4}>
            <Card className='ml-auto mr-auto'>
            <div
             style={{ textAlign: 'center' }}
                              className='ml-auto mr-auto'
            >
                <div>
                    <p    style={{
                                    margin: '8px 0 4px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                 }}>{e.name}</p>
                    <p>{e.qty}</p>
                    {/* {e.in.filter((e) => moment(e.date).format('DD/MM/YYYY') === moment(today).format('DD/MM/YYYY') )} */}
                </div>
            </div>

            </Card>
            </Col>
            </>
        )
    })}
                    
                  </Row>
            </div>
            </Col>
          
        </Row>
    </div>
    </>
  )
}

export default Products
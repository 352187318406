import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefualtLayout';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRecommendCommission } from '../features/adminFeatures/commission/commissionAdminSlice';
import { Statistic, Table } from 'antd';
import Loading from '../components/Loading';

const ExportBonusMatchingSummary = () => {
   const dispatch = useDispatch();

   const { date, id, depth } = useParams();
   const [loadingPage, setLoadingPage] = useState(true);

   const {
      reccommendCommsion,
      isError,
      isSuccess,
      message,
      isLoading,
   } = useSelector((state) => state.commission);

   console.log(reccommendCommsion.result3);

   const dataTable = reccommendCommsion.result3;

   useEffect(() => {
      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      dispatch(getRecommendCommission({ date, id, depth }));
   }, []);

   const column = [
      {
         title: 'userId',
         dataIndex: 'userId',
      },
      {
         title: 'name',
         dataIndex: 'name',
      },
      {
         title: 'amount',
         dataIndex: 'amount',
         render: (value) => (
            <>
               <Statistic value={(value * 5) / 100} />
            </>
         ),
      },
   ];

   return (
      <DefaultLayout>
         <>
            {isLoading ? (
               <>
                  {' '}
                  <Loading />{' '}
               </>
            ) : (
               <>
                  {' '}
                  <div>
                     <h5> รายละเอียดวันที่: {date} </h5>
                  </div>
                  <Table
                     summary={(dataTable) => {
                        let totalAmount = 0;
                        dataTable.forEach(({ amount }) => {
                           totalAmount += amount;
                        });

                        return (
                           <>
                              <Table.Summary.Row>
                                 <Table.Summary.Cell colSpan={2}>
                                    {' '}
                                    <b> รวม</b>{' '}
                                 </Table.Summary.Cell>
                                 <Table.Summary.Cell type='danger'>
                                    <Statistic
                                       suffix='฿'
                                       value={(totalAmount * 5) / 100}
                                    />
                                 </Table.Summary.Cell>
                              </Table.Summary.Row>
                           </>
                        );
                     }}
                     columns={column}
                     dataSource={reccommendCommsion.result3}
                  />
               </>
            )}
         </>
      </DefaultLayout>
   );
};

export default ExportBonusMatchingSummary;
